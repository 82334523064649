/* eslint-disable no-debugger */
import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DataTableBase from "../../Components/Common/DataTableBase/DataTableBase";
import { CreateVenueModal } from "./CreateVenueModal";
import { EditVenueModal } from "./EditVenueModal";
import {
  ImagesCell,
  StatusCell,
} from "../../Components/Common/DataTableBase/components/Cell-Formatting";
import { useVenuesStore } from "../../stores/Venues";
import { t } from "i18next";

export function Venues() {
  const title = t("Venues");
  document.title = title;

  const { getAllVenues, venues, deleteVenue, venuesLoading } = useVenuesStore(
    (state) => state
  );
  useEffect(() => {
    getAllVenues();
  }, []);

  const onChangePage = (page) => {
    getAllVenues({ page: page });
  };

  const columns = [
    {
      name: "Code",
      width: "100px",
      selector: (row) => row.code,
      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Street",
      selector: (row) => row.street,
      sortable: true,
      wrap: true,
    },

    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
      wrap: true,
    },
    {
      name: "Zone",
      selector: (row) => row.zone,
      sortable: true,
      wrap: true,
    },
    {
      name: "Building",
      selector: (row) => row.building,
      sortable: true,
      wrap: true,
      width: "110px",
    },
    {
      name: "Domain Name",
      selector: (row) => row.domainName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Public Email",
      selector: (row) => row.publicEmail,
      sortable: true,
      wrap: true,
      width: "220px",
    },
    {
      name: "Public Contact Number",
      selector: (row) => row.publicContactNumber,
      sortable: true,
      wrap: true,
    },
    {
      name: "Social Media Accounts",
      selector: (row) =>
        `FB:${row.socialMediaAccounts?.facebook || ""} TW: ${
          row.socialMediaAccounts?.twitter || ""
        } IG:${row.socialMediaAccounts?.instagram || ""}`,
      sortable: true,
      wrap: true,
      width: "185px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => <StatusCell text={row.status} />,
      sortable: false,
      maxWidth: "80px",
      center: true,
    },

    {
      name: "Image",
      // selector: (row) => row.images.map((obj) => obj.small),
      cell: (row) => <ImagesCell images={[row.image]} />,
      width: "80px",
      sortable: false,
      wrap: true,
    },
    {
      name: "Logo",
      // selector: (row) => row.images.map((obj) => obj.small),
      cell: (row) => <ImagesCell images={[row.logo]} />,
      width: "80px",
      sortable: false,
      wrap: true,
    },
  ];

  const [showCreateModal, setShowCreateModal] = useState(false);
  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };
  let [selectedVenue, setSelectedVenue] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const toggleEditModal = (row) => {
    setSelectedVenue(row);
    setShowEditModal(!showEditModal);
  };

  const searchHandler = (searchText) => {
    if (!searchText) searchText = undefined;
    getAllVenues({ search: searchText });
  };

  return (
    <React.Fragment>
      <BreadCrumb title={title} pageTitle={title} />

      <DataTableBase
        tableTitle={title}
        data={venues}
        columns={columns}
        loading={venuesLoading}
        // paginationTotalRows={total}
        onChangePage={onChangePage}
        // onChangeRowsPerPage={onChangeRowsPerPage}
        onHeaderAddBtnClick={toggleCreateModal}
        onHeaderDeleteBtnClick={() => {
          alert("Soon");
        }}
        onRowEditBtnClick={toggleEditModal}
        onRowDeleteBtnClick={deleteVenue}
        onSearchIconClick={searchHandler}
      />
      {showCreateModal && (
        <CreateVenueModal
          isOpen={showCreateModal}
          toggle={toggleCreateModal}
          backdrop="static"
          onClosed={(x) => {
            // console.log(x);
          }}
        />
      )}
      {showEditModal && (
        <EditVenueModal
          isOpen={showEditModal}
          toggle={toggleEditModal}
          backdrop="static"
          selectedVenue={selectedVenue}
        />
      )}
    </React.Fragment>
  );
}
