/* eslint-disable no-debugger */
import { create } from "zustand";
import { axiosHelper } from "./helpers";

export const useBookingsStore = create((set, get) => ({
  bookings: [],
  bookingsLoading: false,
  isError: false,
  error: undefined,
  isLoading: false,

  // Methods
  getBookings: async ({ facilityId, bookingSlot: { from, to } }) => {
    const body = {
      page: 1,
      limit: 999999999999,
      // sortDirection: "desc",
      // sortField: "createdAt",
      facilityId: facilityId,
      bookingSlot: {
        from: from,
        to: to,
      },
    };

    try {
      set({ bookingsLoading: true });
      let response = await axiosHelper.post(
        "/system/manage-facilityBooking/get/facilityBookings",
        body
      );

      if (!response.result) {
        return;
      }
      set({ bookings: response.data });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      set({ bookingsLoading: false });
    }
  },

  createBooking: async (body) => {
    try {
      set({ isLoading: true });
      let response = await axiosHelper.post(
        "/system/manage-facilityBooking/create/facilityBooking",
        body
      );

      if (!response.result) {
        return;
      }
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
}));
