import React, { useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  PhoneNumbersCell,
  StatusCell,
} from "../../Components/Common/DataTableBase/components/Cell-Formatting";
import DataTableBase from "../../Components/Common/DataTableBase/DataTableBase";
import { useTenantsStore } from "../../stores/Tenants";
import { CreateTenantModal } from "./CreateTenantModal";
import { UpdateTenantModal } from "./UpdateTenantModal";
import FilterByVenueDD from "../../Components/Common/DataTableBase/FilterByVenueDD";
import { t } from "i18next";

export default function Tenants() {
  const title = t("Tenants");

  document.title = title;

  const { tenants, fetchTenants, isLoading } = useTenantsStore(
    (state) => state
  );

  const onChangePage = (page) => {
    fetchTenants(undefined, page);
  };
  let rowsPerPage = 10;
  const onChangeRowsPerPage = async (newPerPage, page) => {
    rowsPerPage = newPerPage;
    fetchTenants(undefined, page, newPerPage);
  };

  const columns = [
    {
      name: "Code",
      width: "100px",
      selector: (row) => row.code,
      sortable: false,
      wrap: true,
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Venue",
      selector: (row) => row.venueId.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Unit",
      selector: (row) => row.unitId.label,
      cell: (row) => `${row.unitId.label} - #${row.unitId.buildingNumber}`,
      sortable: true,
      wrap: true,
    },
    {
      name: "Cars Details",
      // selector: (row) => row.carsDetails,
      cell: (row) => (
        <div class="d-flex flex-column gap-0">
          {row.carsDetails.map(
            (el) => <span key={el}>- {el.carModel},{el.carNumber}  </span>
          )}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      wrap: true,
      width: "270px",
    },
    {
      name: "Phones",
      sortable: false,
      wrap: true,
      cell: (row) => (
        <PhoneNumbersCell first={row.phoneOne} second={row.phoneTwo} />
      ),
      style: {
        // textAlign: "center",
      },
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => <StatusCell text={row.status} />,
      sortable: false,
      maxWidth: "80px",
      center: true,
    },
  ];

  const [showCreateModal, setShowCreateModal] = useState(false);
  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  let [tenantInfo, setTenantInfo] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const toggleEditModal = (row) => {
    setTenantInfo(row);
    setShowEditModal(!showEditModal);
  };

  const { deleteTenant } = useTenantsStore((state) => state);
  const searchHandler = (searchText) => {
    if (!searchText) searchText = undefined;
    fetchTenants({ search: searchText });
  };

  return (
    <React.Fragment>
      <BreadCrumb title={title} pageTitle={title} />
      <FilterByVenueDD
        getItemsFn={(venueId) => {
          fetchTenants({ venueId: venueId });
        }}
      />
      {
        <DataTableBase
          tableTitle={title}
          data={tenants}
          columns={columns}
          loading={isLoading}
          // paginationTotalRows={total}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onHeaderAddBtnClick={toggleCreateModal}
          onHeaderDeleteBtnClick={() => {
            alert("Soon");
          }}
          onRowEditBtnClick={toggleEditModal}
          onRowDeleteBtnClick={deleteTenant}
          // onActivate={activateTenant}
          // onSuspend={suspendTenant}
          onSearchIconClick={searchHandler}
          actionColWidth="170px"
        />
      }
      {showCreateModal && (
        <CreateTenantModal
          isOpen={showCreateModal}
          toggle={toggleCreateModal}
          backdrop="static"
        />
      )}
      {showEditModal && (
        <UpdateTenantModal
          isOpen={showEditModal}
          toggle={toggleEditModal}
          // backdrop="static"
          tenantInfo={tenantInfo}
        />
      )}
    </React.Fragment>
  );
}
