import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  PhoneNumbersCell,
  StatusCell,
} from "../../Components/Common/DataTableBase/components/Cell-Formatting";
import DataTableBase from "../../Components/Common/DataTableBase/DataTableBase";
import { useStaffsStore } from "../../stores";
import { CreateTenantModal } from "../Tenants/CreateTenantModal";
import { CreateStaffMemberModal } from "./CreateStaffModal";
import { UpdateStaffMemberModal, UpdateTenantModal } from "./UpdateStaffModal";
import { Label } from "reactstrap";
import FilterByVenueDD from "../../Components/Common/DataTableBase/FilterByVenueDD";
import { t } from "i18next";

export default function Staffs() {
  const title = t("Staffs");

  document.title = title;

  const {
    staffs,
    getStaffs,
    deleteStaff,
    suspendStaff,
    activateStaff,
    isLoading,
  } = useStaffsStore((state) => state);

  const onChangePage = (page) => {
    getStaffs(undefined, page);
  };
  let rowsPerPage = 10;
  const onChangeRowsPerPage = async (newPerPage, page) => {
    rowsPerPage = newPerPage;
    getStaffs(undefined, page, newPerPage);
  };

  const columns = [
    {
      name: "Code",
      width: "100px",
      selector: (row) => row.code,
      sortable: false,
      wrap: true,
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      wrap: true,
      width: "270px",
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Phones",
      sortable: false,
      wrap: true,
      cell: (row) => (
        <PhoneNumbersCell first={row.phoneOne} second={row.phoneTwo} />
      ),
      style: {
        // textAlign: "center",
      },
    },
    {
      name: "Role",
      selector: (row) => row.roles.join(" , "),
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => <StatusCell text={row.status} />,
      sortable: false,
      maxWidth: "80px",
      center: true,
    },
  ];
  const [showCreateModal, setShowCreateModal] = useState(false);
  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  let [staffInfo, setStaffInfo] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const toggleEditModal = (row) => {
    setStaffInfo(row);
    setShowEditModal(!showEditModal);
  };
  const searchHandler = (searchText) => {
    if (!searchText) searchText = undefined;
    getStaffs({search:searchText});
  };

  return (
    <React.Fragment>
      <BreadCrumb title={title} pageTitle={title} />
      <FilterByVenueDD
        getItemsFn={(venueId) => {
          getStaffs({ venueId: venueId });
        }}
      />
      <DataTableBase
        tableTitle={title}
        data={staffs}
        columns={columns}
        loading={isLoading}
        // paginationTotalRows={total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        showActionButtons={true}
        showActionColumn={true}
        onHeaderAddBtnClick={toggleCreateModal}
        onHeaderDeleteBtnClick={() => {
          alert("Soon");
        }}
        onRowEditBtnClick={toggleEditModal}
        onRowDeleteBtnClick={deleteStaff}
        // onActivate={activateStaff}
        // onSuspend={suspendStaff}
        onSearchIconClick={searchHandler}
      />
      {showCreateModal && (
        <CreateStaffMemberModal
          isOpen={showCreateModal}
          toggle={toggleCreateModal}
          backdrop="static"
        />
      )}
      {showEditModal && (
        <UpdateStaffMemberModal
          isOpen={showEditModal}
          toggle={toggleEditModal}
          backdrop="static"
          staffInfo={staffInfo}
        />
      )}
    </React.Fragment>
  );
}
