import React, { useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DataTableBase from "../../Components/Common/DataTableBase/DataTableBase";
import { useMaintenanceStore } from "../../stores";
import { CreateMaintenanceModal } from "./CreateMaintenanceModal";
import { UpdateMaintenanceModal } from "./UpdateMaintenanceModal";

import {
  ImagesCell,
  StatusCell,
} from "../../Components/Common/DataTableBase/components/Cell-Formatting";
import FilterByVenueDD from "../../Components/Common/DataTableBase/FilterByVenueDD";
import { t } from "i18next";
export default function MaintenanceRecords() {
  const title = t("Maintenance Records");

  document.title = title;

  const {
    records,
    getMaintenanceRecords,
    deleteMaintenanceRequest,
    recordsLoading,
  } = useMaintenanceStore((state) => state);

  const onChangePage = (page) => {
    getMaintenanceRecords(undefined, page);
  };
  let rowsPerPage = 10;
  const onChangeRowsPerPage = async (newPerPage, page) => {
    rowsPerPage = newPerPage;
    getMaintenanceRecords(undefined, page, newPerPage);
  };

  const columns = [
    {
      name: "Code",
      width: "100px",
      selector: (row) => row.code,
      sortable: false,
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) => row.type?.type,
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      wrap: true,
    },
    {
      name: "Images",
      // selector: (row) => row.images.map((obj) => obj.small),
      cell: (row) => <ImagesCell images={row.images} />,
      width: "140px",
      sortable: false,
      wrap: true,
    },
    {
      name: "Tenant",
      selector: (row) => `${row.tenant?.firstName} ${row.tenant?.lastName}`,
      sortable: true,
      wrap: true,
    },
    // {
    //   name: "Venue",
    //   selector: (row) => `${row.venueId?.name || ''} `,
    //   sortable: true,
    //   wrap: true,
    // },
    {
      name: "Unit",
      selector: (row) => `${row.unit?.label} - # ${row.unit?.buildingNumber}`,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => <StatusCell text={row.status} />,
      sortable: false,
      maxWidth: "80px",
      center: true,
    },

    // {
    //   name: "Completion Date",
    //   selector: (row) => row.date,
    //   formatter: (row) => new Date(row.completionDate).toLocaleString(),
    //   sortable: true,
    //   wrap: true,
    // },
    // {
    //   name: "CompletionPhotos",
    //   selector: (row) => row.completionPhotos.join(" , "),
    //   sortable: true,
    //   wrap: true,
    // },
    // {
    //   name: "Comments",
    //   selector: (row) => row.comments.join(" , "),
    //   sortable: true,
    //   wrap: true,
    // },
    // {
    //   name: "FeedBack Rating",
    //   selector: (row) => row.feedBackRating.join(" , "),
    //   sortable: true,
    //   wrap: true,
    // },
  ];
  const [showCreateModal, setShowCreateModal] = useState(false);
  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  let [maintenanceInfo, setMaintenanceInfo] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const toggleEditModal = (row) => {
    setMaintenanceInfo(row);
    setShowEditModal(!showEditModal);
  };

  const searchHandler = (searchText) => {
    if (!searchText) searchText = undefined;
    getMaintenanceRecords({ search: searchText });
  };

  return (
    <React.Fragment>
      <BreadCrumb title={title} pageTitle={title} />
      <FilterByVenueDD
        getItemsFn={(venueId) => {
          getMaintenanceRecords({ venue: venueId });
        }}
      />

      <DataTableBase
        tableTitle={title}
        data={records}
        columns={columns}
        loading={recordsLoading}
        // paginationTotalRows={total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        showActionButtons={true}
        showActionColumn={true}
        onHeaderAddBtnClick={toggleCreateModal}
        onHeaderDeleteBtnClick={() => {
          alert("Soon");
        }}
        onRowEditBtnClick={toggleEditModal}
        onRowDeleteBtnClick={deleteMaintenanceRequest}
        // customActionBtns={(row) => (
        //   <>
        //     {row.status === "Submitted" && (
        //       <button
        //         className="btn btn-sm btn-success"
        //         onClick={() => {
        //           attendMaintenanceRequest(row._id);
        //         }}
        //         title="Attend"
        //       >
        //         Attend
        //       </button>
        //     )}
        //     {row.status === "Submitted" && (
        //       <button
        //         className="btn btn-sm btn-danger"
        //         onClick={() => {
        //           cancelMaintenanceRequest(row._id);
        //         }}
        //         title="Cancel"
        //       >
        //         Cancel
        //       </button>
        //     )}
        //   </>
        // )}
        actionColWidth="100px"
        onSearchIconClick={searchHandler}
      />
      {showCreateModal && (
        <CreateMaintenanceModal
          isOpen={showCreateModal}
          toggle={toggleCreateModal}
          backdrop="static"
        />
      )}
      {showEditModal && (
        <UpdateMaintenanceModal
          isOpen={showEditModal}
          toggle={toggleEditModal}
          backdrop="static"
          maintenanceInfo={maintenanceInfo}
        />
      )}
    </React.Fragment>
  );
}
