import { useAuth } from "./Auth/Auth";
import { useMaintenanceStore } from "./Maintenance";
import { useTenantsStore } from "./Tenants";
import { useFacilitiesStore } from "./Facilities";
import { useVisitorsPassesStore } from "./VisitorsPasses";
import { useVisitorsStore } from "./Visitors";
import { useStaffsStore } from "./Staffs";

export {
  useAuth,
  useMaintenanceStore,
  useTenantsStore,
  useVisitorsPassesStore,
  useFacilitiesStore,
  useVisitorsStore,
  useStaffsStore,
};
