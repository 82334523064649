/* eslint-disable no-debugger */
import { create } from "zustand";
import { axiosHelper } from "./helpers";
import { getLocalizedData } from "./helpers/localize_helper";
export const useFacilitiesStore = create((set, get) => ({
  facilities: [],
  facilitiesLoading: false,
  isError: false,
  error: undefined,
  isLoading: false,
  filters: null,
  // Methods
  getFacilities: async (filters) => {
    set({ filters: filters });
    // debugger;
    const body = {
      // search: search,
      sortDirection: "asc",
      // sortField: "createdAt",
      // dateFrom: "",
      // dateTo: "",
      ...filters,
      page: filters?.page || 1,
      limit: filters?.limit || 10,
    };
    try {
      set({ facilitiesLoading: true });
      let response = await axiosHelper.post(
        "/system/manage-facilities/get/facilities",
        body
      );

      if (!response.result) {
        return;
      }

      const localizedData = getLocalizedData(response.data);

      // console.log(localizedData);
      set({ facilities: localizedData });
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      set({ facilitiesLoading: false });
    }
  },

  getFacility: async (facilityId) => {
    if (!facilityId) return;
    try {
      set({ isLoading: true });
      let response = await axiosHelper.get(
        `/system/manage-facilities/get/facility/${facilityId}`
      );
      if (!response.result) {
        return;
      }
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },

  createFacility: async (facilityData) => {
    const body = facilityData;
    set({ facilitiesLoading: true });
    try {
      let response = await axiosHelper.post(
        "/system/manage-facilities/create/facility",
        body
      );
      if (!response.result) {
        return;
      }
      get().getFacilities(get().filters);
      return response;
    } catch (error) {
      return error.response;
    } finally {
      set({ facilitiesLoading: false });
    }
  },
  updateFacility: async (id, facilityUpdateData) => {
    const body = facilityUpdateData;
    if (
      body.closingPeriod &&
      (body.closingPeriod.from == "" || body.closingPeriod.to == "")
    )
      delete body.closingPeriod;
    set({ facilitiesLoading: true });
    try {
      let response = await axiosHelper.put(
        `/system/manage-facilities/update/facility/${id}`,
        body
      );
      if (!response.result) {
        return;
      }
      get().getFacilities(get().filters);
      return response;
    } catch (error) {
      return error.response;
    } finally {
      set({ facilitiesLoading: false });
    }
  },

  deleteFacility: async (facilityId) => {
    set({ facilitiesLoading: true });
    try {
      let response = await axiosHelper.put(
        `/system/manage-facilities/delete/facility/${facilityId}`
      );
      if (!response.result) {
        return;
      }
      get().getFacilities(get().filters);
      return response;
    } catch (error) {
      return error.response;
    } finally {
      set({ facilitiesLoading: false });
    }
  },

  // Facilities Types
  facilityTypes: [],
  facilitiesTypesLoading: false,
  getFacilityTypes: async () => {
    const body = {
      // page: 1,
      // limit: 10,
      // sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "2023-01-31",
      // dateTo: "2023-03-31",
    };
    set({ facilitiesTypesLoading: true });
    try {
      const response = await axiosHelper.post(
        "/super-admin/manage-facilityType/get/facilityTypes"
      );
      if (!response.result) {
        return;
      }
      set({ facilityTypes: response.data });
      // console.log(response.data)
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      set({ facilitiesTypesLoading: false });
    }
  },

  // Get Facilities for A venue
  getFacilitiesByVenue: async (venueId) => {
    set({ isLoading: true });
    try {
      const response = await axiosHelper.get(
        `/system/manage-facilities/get/facilities-list/${venueId}`
      );
      if (!response.result) {
        return;
      }
      // console.log(response.data)
      const localizedData = getLocalizedData(response.data);

      return localizedData;
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
}));
