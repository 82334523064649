import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useStaffsStore } from "../../stores";
import { RenderFormikInput } from "../../Components/Common/Forms/FormikInputHelper";
import { useConfirmDialogStore } from "../../stores/Modal/ConfirmDialogStore";
import { async } from "q";
import { useVenuesStore } from "../../stores/Venues";
import { t } from "i18next";

export function UpdateStaffMemberModal({
  isOpen,
  toggle,
  staffInfo,
  ...restProps
}) {
  const getUserVenues = useVenuesStore((state) => state.getUserVenues);

  const formik = useFormik({
    initialValues: {
      firstName: staffInfo?.firstName,
      lastName: staffInfo?.lastName,
      phoneOne: staffInfo?.phoneOne,
      phoneTwo: staffInfo?.phoneTwo,
      venuesIds: staffInfo?.venuesIds,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t("Required")),
      lastName: Yup.string().required(t("Required")),
      phoneOne: Yup.string().required(t("Required")),
      phoneTwo: Yup.string().required(t("Required")),
      venuesIds: Yup.array().min(1).required(t("Required")),
    }),
    onSubmit: async (values) => {
      // console.log(await createTenant(values))
      const { result, message } = await updateStaff(staffInfo?._id, values);
      if (result) {
        toggle();
      }
    },
  });
  const [venuesData, setVenuesData] = useState([])


  // const getDefaultValues = async () => {
  //   const venues = await getUserVenues();
  //   let data = staffInfo?.venuesIds.map(id => {
  //     let venuesData = venues.find(venue => venue._id === id)
  //     return {
  //       value: venuesData._id,
  //       label: venuesData.name
  //     }
  //   })

  //   return data
  // }
  
  const fieldsToRender = [
    { fieldName: "firstName", label: "First Name" },
    { fieldName: "lastName", label: "Last Name" },
    { fieldName: "phoneOne", label: "First Phone Number" },
    { fieldName: "phoneTwo", label: "Second Phone Number" },

    {
      fieldName: "venuesIds",
      label: t("Select Venue"),
      type: "chips",
      loadOptions: async () => {
        const venues = await getUserVenues();
        const mappedVenues = venues.map((el) => ({
          value: el._id,
          label: el.name,
        }));
        return mappedVenues;
      },
      //set default values
      // loadDefaultValue: async () => {
      //   return await getDefaultValues()
      // }
    },
  ];


  const { updateStaff, isLoading, suspendStaff, activateStaff } =
    useStaffsStore((state) => state);

  const [isActive, setIsActive] = useState(staffInfo?.status === "Active");
  const toggleStatus = async () => {
    showConfirm(async () => {
      if (isActive) {
        const result = await suspendStaff(staffInfo._id);
        if (result) {
          setIsActive(false);
        }
      } else {
        const result = await activateStaff(staffInfo._id);
        if (result) {
          setIsActive(true);
        }
      }
    });
  };

  const { showConfirm } = useConfirmDialogStore((state) => state);

  return (
    <Modal keyboard={false} isOpen={isOpen} toggle={toggle} {...restProps}>
      <ModalHeader>Edit Staff Information</ModalHeader>
      <ModalBody>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column gap-3"
        >
          {fieldsToRender.map((field) => (
            <Col key={field.fieldName}>{RenderFormikInput(formik, field)}</Col>
          ))}

          <Col className="d-flex align-items-center gap-2">
            <Label className="m-0">Status:</Label>
            <Badge
              className="px-4 py-2"
              color={isActive ? "success" : "danger"}
            >
              {isActive ? "Active" : "Suspended"}
            </Badge>
            <Button
              color={isActive ? "danger" : "success"}
              className="ms-auto"
              size="sm"
              onClick={toggleStatus}
            >
              {isActive ? "Suspend" : "Activate"}
            </Button>
          </Col>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          className="order-1"
          type="submit"
          onClick={() => {
            formik.submitForm();
          }}
          disabled={!formik.dirty || !formik.isValid || isLoading}
        >
          {isLoading ?  t("Loading"):  t("Save")}
        </Button>
        {!isLoading && (
          <Button color="danger" onClick={toggle}>
             {t('Cancel')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
