import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useConfirmDialogStore } from "./ConfirmDialogStore";
export function ConfirmDialog(props) {
  const { isOpen, toggle, confirm, title } = useConfirmDialogStore(
    (state) => state
  );
  return (
    isOpen && (
      <Modal keyboard={false} isOpen={isOpen} toggle={toggle} centered={true}>
        <ModalHeader>{title || "Confirm Dialog"}</ModalHeader>
        <ModalBody>
          <h3 className="text-center mb-0">Are You Sure?</h3>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={confirm}>
            Yes
          </Button>
          <Button color="danger" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    )
  );
}
