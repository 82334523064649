import React from "react";
import { useConfirmDialogStore } from "../../../../stores/Modal/ConfirmDialogStore";

export default function RowActionBtns({
  row,
  onRowEditBtnClick,
  onRowDeleteBtnClick,
  onSuspend,
  onActivate,
  customActionBtns,
}) {
  const { showConfirm } = useConfirmDialogStore((state) => state);
  const editRow = () => {
    onRowEditBtnClick(row);
  };
  const deleteRow = () => {
    showConfirm(() => {
      onRowDeleteBtnClick(row._id);
    });
  };
  const activateRow = () => {
    onActivate(row._id);
  };
  const suspendRow = () => {
    onSuspend(row._id);
  };
  const isSuspended = row.status === "Suspended";
  const isActive = row.status === "Active";
  const isPending = row.status === "Pending";

  return (
    <div className="d-flex flex-wrap align-items-stretch justify-content-center gap-1">
      <button className="btn btn-sm btn-warning" onClick={editRow} title="Edit">
        <i className="ri-edit-2-line"></i>
      </button>

      {onActivate && !isPending && !isActive && (
        <button
          className="btn btn-sm btn-success"
          onClick={activateRow}
          title="Activate"
        >
          Activate
        </button>
      )}
      {onSuspend && !isSuspended && !isPending && (
        <button
          className="btn btn-sm btn-danger"
          onClick={suspendRow}
          title="Suspend"
        >
          Suspend
        </button>
      )}
      <button
        className="btn btn-sm btn-danger"
        onClick={deleteRow}
        title="Delete"
      >
        <i className="ri-delete-bin-line"></i>
      </button>
      {customActionBtns && customActionBtns(row)}
    </div>
  );
}
