/* eslint-disable no-debugger */
import { create } from "zustand";
import { axiosHelper } from "./helpers";

export const useUnitsStore = create((set, get) => ({
  units: [],
  isError: false,
  error: undefined,
  isLoading: false,
  getUnits: async (id) => {
    const body = {
      // page: 1,
      // limit: 10,
      // sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "2023-01-31",
      // dateTo: "2023-03-31",
    };
    set({ isLoading: true });
    try {
      const response = await axiosHelper.get(
        `/system/manage-units/get/venue-units/${id}`
      );
      if (!response.result) {
        return;
      }
      set({ units: response.data });
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
}));
