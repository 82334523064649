import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
// import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";

import { useRef } from "react";
import { useEffect } from "react";
import { Button, Card, CardBody, Spinner } from "reactstrap";
import { useBookingsStore } from "../../../stores/Bookings";
import { t } from "i18next";
import arLocale from "@fullcalendar/core/locales/ar";
import enLocale from "@fullcalendar/core/locales/en-au";

export default function FacilitiesCalendar(props) {
  const { getBookings, bookings, bookingsLoading } = useBookingsStore(
    (state) => state
  );
  const [events, setEvents] = useState([]);
  const [bodyQuery, setBodyQuery] = useState(null);
  const calendarRef = useRef();
  const [calendarApi, setCalendarApi] = useState(null);
  useEffect(() => {
    if (!props.facilityId) return;
    const calendarApi = calendarRef.current.getApi();
    setCalendarApi(calendarApi);
    setBodyQuery({
      facilityId: props.facilityId,
      bookingSlot: {
        from: calendarApi?.view.currentStart,
        to: calendarApi?.view.currentEnd,
      },
    });
  }, [props.facilityId]);
  useEffect(() => {
    if (!bodyQuery) return;
    getBookings(bodyQuery);
  }, [bodyQuery]);

  useEffect(() => {
    setEvents(mapBookingsToEvents(bookings));
  }, [bookings]);

  const handleDateClick = (info) => {
    // console.log(info);
  };
  const handleEventClick = (info) => {
    // console.log(info);
  };

  // const { currentLang } = useLanguageStore((state) => state);
  const isCurrentLangAr = localStorage.getItem("I18N_LANGUAGE") === "ar";
  return (
    <>
      {/* <CreateBooking /> */}
      <Card>
        <CardBody>
          <span className="fs-5">
            <span className=" fw-bold">{events?.length}</span>{" "}
            {t("Bookings found")}
          </span>
          <FullCalendar
            locale={isCurrentLangAr ? arLocale : enLocale}
            height={"700px"}
            // contentHeight={'auto'}
            // aspectRatio={1.8}
            ref={calendarRef}
            plugins={[timeGridPlugin, interactionPlugin]}
            handleWindowResize={true}
            initialView="timeGrid"
            duration={{ days: 5 }}
            // Data Source
            events={events}
            // Custom Rendering event
            // eventContent={renderEventContent}
            // Events
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            // headerToolbar={{
            //   right: "prev,next today",
            //   center: "title",
            //   left: "",
            // }}
            // customButtons={{}}
            customButtons={{
              prev: {
                click: function () {
                  calendarApi?.prev();
                },
                icon: " ",
              },
              next: {
                click: function () {
                  calendarApi?.next();
                },
                icon: " ",
                // text:"Meky"
              },
              today: {
                text: t("Today"),
                click: function () {
                  calendarApi?.today();
                },
              },
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}

// Custom Rendering event
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.event.extendedProps?.isMine ? "My - " : "Others - "}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

const mapBookingsToEvents = (bookings) => {
  return bookings.map((booking) => {
    return {
      title:
        booking.tenantId?.firstName +
        " " +
        booking.tenantId?.lastName +
        " - unit: " +
        booking.tenantId?.unitId?.buildingNumber,
      start: booking.bookingSlot?.from,
      end: booking.bookingSlot?.to,
      classNames: "m-0",
      // isMine: false,
    };
  });
};
