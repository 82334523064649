import React, { useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useStaffsStore } from "../../stores";
import { RenderFormikInput } from "../../Components/Common/Forms/FormikInputHelper";
import { useVenuesStore } from "../../stores/Venues";
import { t } from "i18next";

export function CreateStaffMemberModal({
  isOpen,
  toggle,
  onClosed,
  ...restProps
}) {


  const [venueValue, setVenueValue] = useState(null);
  const [venuesOptions, setVenuesOptions] = useState(null);
  const getUserVenues = useVenuesStore((state) => state.getUserVenues);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneOne: "",
      phoneTwo: "",
      role: "",
      venuesIds: [],
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t("Required")),
      lastName: Yup.string().required(t("Required")),
      email: Yup.string().email("Invalid Email Address").required(t("Required")),
      password: Yup.string()
        .min(8, "Password length should be at least 8 characters")
        .required(t("Required")),
      phoneOne: Yup.string().required(t("Required")),
      phoneTwo: Yup.string().required(t("Required")),
      role: Yup.string().required(t("Required")),
      venuesIds: Yup.array().min(1,`${t("Can't be less than")} 1`).required('required')
      // One of !
    }),
    onSubmit: async (values) => {
      const body = { ...values }
      const { result, message } = await createStaff(values);
      toggle();
    },
  })
  const setFirstItem = (firstItem) => {
    // if (!firstItem) return;
    // setVenueValue(firstItem);
    // formik.setFieldValue("venuesIds", [firstItem]);
  };
  
  const fieldsToRender = [
    { fieldName: "firstName", label: "First Name" },
    { fieldName: "lastName", label: "Last Name" },
    { fieldName: "email", label: "Email" },
    { fieldName: "password", label: "Password" },
    { fieldName: "phoneOne", label: "First Phone Number" },
    { fieldName: "phoneTwo", label: "Second Phone Number" },
    {
      fieldName: "role",
      type: "select",
      label: "Rule",
      options: [
        { value: "admin", label: "Admin" },
        { value: "maintenance", label: "Maintenance" },
        { value: "security", label: "Security" },
      ],
    },
    {
      fieldName: "venuesIds",
      label: t("Select Venue"),
      type: "chips",
      value: [venueValue],
      loadOptions: async () => {
        const venues = await getUserVenues();
        // console.log(venues);
        const mappedVenues = venues.map((el) => ({
          value: el._id,
          label: el.name,
        }));
        setFirstItem(mappedVenues[0]);
        return mappedVenues;
      }
    },

  ];



  const { createStaff, isLoading } = useStaffsStore((state) => state);

  return (
    <Modal keyboard={false} isOpen={isOpen} toggle={toggle} onClosed={onClosed}  {...restProps}>
      <ModalHeader>{t('Create New Staff Member')}</ModalHeader>
      <ModalBody>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column gap-3"
        >
          {fieldsToRender.map((field) => (
            <Col key={field.fieldName}>{RenderFormikInput(formik, field)}</Col>
          ))}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          className="order-1"
          type="submit"
          onClick={() => {
            formik.submitForm();
          }}
          disabled={!formik.dirty || !formik.isValid || isLoading}
        >
          {isLoading ?  t("Loading"):  t("Save")}
        </Button>
        {!isLoading && (
          <Button color="danger" onClick={toggle}>
             {t('Cancel')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
