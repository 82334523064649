/* eslint-disable no-debugger */
import { create } from "zustand";
import { axiosHelper } from "./helpers";

export const useStaffsStore = create((set, get) => ({
  staffs: [],
  isLoading: false,
  isError: false,
  error: undefined,
  filters:null,
  // Methods
  getStaffs: async (filters) => {
    set({ filters: filters });

    const body = {
      // search: search,
      // sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "",
      // dateTo: "",
      ...filters,
      page: filters?.page || 1,
      limit: filters?.limit || 10,
    };

    try {
      set({ isLoading: true });
      let response = await axiosHelper.post(
        "/system/manage-staff/get/staffs",
        body
      );

      if (!response.result) {
        return;
      }
      set({ staffs: response.data, isLoading: false });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
  createStaff: async (StaffData) => {
    const body = StaffData;
    set({ isLoading: true });
    try {
      let response = await axiosHelper.post(
        "/system/manage-staff/create/staff",
        body
      );
      if (!response.result) {
        return;
      }
      get().getStaffs(get().filters);
      return response;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },
  updateStaff: async (id, StaffUpdateData) => {
    const body = StaffUpdateData;
    set({ isLoading: true });
    try {
      let response = await axiosHelper.put(
        `/system/manage-staff/update/staff/${id}`,
        body
      );
      if (!response.result) {
        return;
      }
      get().getStaffs(get().filters);
      return response;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },

  deleteStaff: async (StaffId) => {
    set({ isLoading: true });
    try {
      let response = await axiosHelper.put(`/client/delete/staff/${StaffId}`);
      if (!response.result) {
        return;
      }
      get().getStaffs(get().filters);
      return response;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },

  suspendStaff: async (StaffId) => {
    // debugger
    let url = `/system/manage-staff/suspend/staff/${StaffId}`;
    set({ isLoading: true });
    try {
      let response = await axiosHelper.put(url);
      if (!response.result) {
        return;
      }
      get().getStaffs(get().filters);
      return response.result;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },
  activateStaff: async (StaffId) => {
    let url = `/system/manage-staff/activate/staff/${StaffId}`;
    set({ isLoading: true });
    try {
      let response = await axiosHelper.put(url);
      if (!response.result) {
        return;
      }
      get().getStaffs(get().filters);
      return response.result;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },
}));
