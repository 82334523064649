/* eslint-disable no-debugger */
import { create } from "zustand";
import { axiosHelper } from "./helpers";
import { getLocalizedData } from "./helpers/localize_helper";

export const useVenuesStore = create((set, get) => ({
  venues: [],
  isError: false,
  error: undefined,
  isLoading: false,
  venuesLoading: false,
  getAllVenues: async (filters) => {
    set({ filters: filters });

    // debugger;
    const body = {
      // search: search,
      // sortField: "createdAt",
      // dateFrom: "",
      // dateTo: "",
      ...filters,
      sortDirection: filters?.sortDirection || "asc",
      page: filters?.page || 1,
      limit: filters?.limit || 10,
    };
    try {
      set({ venuesLoading: true });
      let response = await axiosHelper.post(
        "/system/manage-venues/get/venues",
        body
      );

      if (!response.result) {
        return;
      }
      set({ venues: response.data });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      set({ venuesLoading: false });
    }
  },

  createVenue: async (venueData) => {
    const body = venueData;
    set({ isLoading: true });
    try {
      let response = await axiosHelper.post(
        "/system/manage-venues/create/venue",
        body
      );
      if (!response.result) {
        return;
      }
      get().getAllVenues();
      return response;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },
  updateVenue: async (id, venueUpdateData) => {
    const body = venueUpdateData;
    set({ isLoading: true });
    try {
      let response = await axiosHelper.put(
        `/system/manage-venues/update/venue/${id}`,
        body
      );
      if (!response.result) {
        return;
      }
      get().getAllVenues();
      return response;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },

  deleteVenue: async (venueId) => {
    set({ isLoading: true });
    try {
      let response = await axiosHelper.put(
        `/system/manage-venues/delete/venue/${venueId}`
      );
      if (!response.result) {
        return;
      }
      get().getAllVenues();
      return response;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },

  getUserVenues: async () => {
    const body = {
      // page: 1,
      // limit: 10,
      // sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "2023-01-31",
      // dateTo: "2023-03-31",
    };
    set({ isLoading: true });
    try {
      const response = await axiosHelper.get(
        "/system/manage-venues/get/user-venues"
      );
      if (!response.result) {
        return;
      }
      const localizedData = getLocalizedData(response.data);
      // console.log(localizedData)
      set({ venues: localizedData });
      // console.log(response.data)
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
}));
