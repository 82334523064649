import React, { useEffect, useState } from "react";
import { useVenuesStore } from "../../../stores/Venues";
import { Label } from "reactstrap";
import AsyncSelect from "react-select/async";
import { reactSelectStyling } from "../Forms/FormikInputHelper";
import { t } from "i18next";

export default function FilterByVenueDD({ getItemsFn }) {
  const { getUserVenues, venues } = useVenuesStore((state) => state);
  const [venueItem, setVenueItem] = useState([]);
  const loadVenuesOptions = async () => {
    const venues = await getUserVenues();
    const mapped = venues.map((el) => ({
      value: el._id,
      label: el.name,
    }));

    const firstItem = mapped.length > 0 ? mapped[0] : null;
    // debugger;
    setVenueItem(firstItem);

    return mapped;
  };

  const onVenueChangeHandler = (value) => {
    setVenueItem(value);
  };
  useEffect(() => {
    if (!venueItem?.value) return;
    getItemsFn(venueItem.value);
  }, [venueItem]);

  return (
    <div className="d-flex align-items-center gap-2 mb-2 w-100">
      <Label className="form-label m-0 fs-5">{t('Select')} {t('Venue')}</Label>
      <div className="w-25" style={{ zIndex: 50 }}>
        <AsyncSelect
          cacheOptions
          loadOptions={loadVenuesOptions}
          {...reactSelectStyling}
          defaultOptions
          onChange={onVenueChangeHandler}
          value={venueItem}
          placeholder={t('Search')}
        />
      </div>
    </div>
  );
}
