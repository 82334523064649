/* eslint-disable no-debugger */
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTenantsStore } from "../../stores/Tenants";
import { RenderFormikInput } from "../../Components/Common/Forms/FormikInputHelper";
import { useVenuesStore } from "../../stores/Venues";
import { useUnitsStore } from "../../stores/Units";
import { t } from "i18next";

export function CreateTenantModal({ isOpen, toggle, onClosed, ...restProps }) {
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneOne: "",
      phoneTwo: "",
      carsDetails1: {
        carNumber: "",
        carModel: "",
      },
      carsDetails2: {
        carNumber: "",
        carModel: "",
      },
      venueId: "",
      unitId: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t("Required")),
      lastName: Yup.string().required(t("Required")),
      email: Yup.string().email("Invalid Email Address").required(t("Required")),
      password: Yup.string()
        .min(8, "Password length should be at least 8 characters")
        .required(t("Required")),
      phoneOne: Yup.string().required(t("Required")),
      phoneTwo: Yup.string().optional(),
      carsDetails1: Yup.object().shape({
        carNumber: Yup.string(),
        carModel: Yup.string(),
      }),
      carsDetails2: Yup.object().shape({
        carNumber: Yup.string(),
        carModel: Yup.string(),
      }),

      venueId: Yup.string().required(t("Required")),
      unitId: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      const carsDetails = [values.carsDetails1, values.carsDetails2];
      const body = { ...values, carsDetails };
      delete body.carsDetails1;
      delete body.carsDetails2;
      const { result, message } = await createTenant(body);
      if (result) {
        toggle();
      }
    },
  });
  const [unitsDropDownItem, setUnitsDropDownItem] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);

  const fieldsToRender = [
    { fieldName: "firstName", label: "First Name" },
    { fieldName: "lastName", label: "Last Name" },
    { fieldName: "email", label: "Email", fullWidth: true },
    { fieldName: "password", label: "Password", fullWidth: true },
    { fieldName: "phoneOne", label: "First Phone Number", fullWidth: true },
    { fieldName: "phoneTwo", label: "Second Phone Number", fullWidth: true },
    { fieldName: "carsDetails1.carNumber", label: "First Car Number" },
    { fieldName: "carsDetails1.carModel", label: "First Car Model" },
    { fieldName: "carsDetails2.carNumber", label: "Second Car Number" },
    { fieldName: "carsDetails2.carModel", label: "Second Car Model" },
    {
      fieldName: "venueId",
      label: t("Select Venue"),
      type: "select",
      value: selectedVenue,
      loadOptions: async () => {
        const venues = await getUserVenues();
        const mappedVenues = venues.map((el) => ({
          value: el._id,
          label: el.name,
        }));
        setFirstItem(mappedVenues[0]);

        const units = await getUnits(mappedVenues[0].value);
        const mappedUnits = units.map((el) => ({
          value: el._id,
          label: el.label,
        }));
        setUnitsDropDownItem(mappedUnits);
        return mappedVenues;
      },
      onChange: async (selectedOption) => {
        setSelectedVenue(selectedOption);
        await getUnitsForDropdown(selectedOption);
      },
    },
    {
      fieldName: "unitId",
      label: "Select Unit",
      type: "select",
      options: unitsDropDownItem,
      value: selectedUnit,
      onChange: (option) => {
        setSelectedUnit(option);
      },
    },
  ];
  const setFirstItem = async (firstItem) => {
    if (!firstItem) return;
    setSelectedVenue(firstItem);
    formik.setFieldValue("venueId", firstItem.value);
    await getUnitsForDropdown(firstItem);
  };

  const getUnitsForDropdown = async (selectedOption) => {
    const units = await getUnits(selectedOption.value);
    const mappedUnits = units.map((el) => ({
      value: el._id,
      label: el.label,
    }));
    setUnitsDropDownItem(mappedUnits);
    resetSelectedUnit();
  };
  useEffect(() => {
    if (!selectedVenue) return;
    getUnitsForDropdown(selectedVenue);
  }, [selectedVenue]);
  const resetSelectedUnit = () => {
    setSelectedUnit(null);
    formik.setFieldValue("unitId", "");
  };
  const { createTenant, isLoading } = useTenantsStore((state) => state);
  const getUserVenues = useVenuesStore((state) => state.getUserVenues);
  const getUnits = useUnitsStore((state) => state.getUnits);

  return (
    <Modal keyboard={false} isOpen={isOpen} toggle={toggle} onClosed={onClosed}  {...restProps}>
      <ModalHeader>{t('Create New Tenant')}</ModalHeader>
      <ModalBody>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-wrap justify-content-between"
        >
          {fieldsToRender.map((field) => (
            <div
              key={field.fieldName}
              className={`flex-fill mb-2${field.fullWidth ? "w-100" : ""}`}
              style={field.fullWidth ? {} : { width: "48%", marginRight: 2 }}
            >
              {RenderFormikInput(formik, field)}
            </div>
          ))}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          className="order-1"
          type="submit"
          onClick={() => {
            formik.submitForm();
          }}
          // disabled={!formik.dirty || !formik.isValid || isLoading}
        >
          {isLoading ?  t("Loading"):  t("Save")}
        </Button>
        {!isLoading && (
          <Button color="danger" onClick={toggle}>
            {t("Cancel")}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
