import React from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { StatusCell } from "../../Components/Common/DataTableBase/components/Cell-Formatting";
import DataTableBase from "../../Components/Common/DataTableBase/DataTableBase";
import { useVisitorsPassesStore } from "../../stores";
import FilterByVenueDD from "../../Components/Common/DataTableBase/FilterByVenueDD";
import { t } from "i18next";

export default function VisitorsPasses() {
  const title = t("Visitors Passes");

  document.title = title;

  const { visitorsPasses, getVisitorsPasses, isLoading } =
    useVisitorsPassesStore((state) => state);
  const onChangePage = (page) => {
    getVisitorsPasses(undefined, page);
  };
  let rowsPerPage = 10;
  const onChangeRowsPerPage = async (newPerPage, page) => {
    rowsPerPage = newPerPage;
    getVisitorsPasses(undefined, page, newPerPage);
  };

  const columns = [
    {
      name: "Code",
      width: "100px",
      selector: (row) => row.code,
      sortable: false,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => new Date(row.date).toDateString(),
      sortable: true,
      wrap: true,
    },
    {
      name: "Car Number",
      selector: (row) => row.carNumber,
      sortable: true,
      wrap: true,
    },
    {
      name: "Purpose",
      selector: (row) => row.purpose,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.visitorId.email,
      sortable: true,
      wrap: true,
      width: "270px",
    },

    {
      name: "Phone Number",
      selector: (row) => row.visitorId.phone,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => <StatusCell text={row.status} />,
      sortable: false,
      maxWidth: "80px",
      center: true,
    },
  ];
  const searchHandler = (searchText) => {
    if (!searchText) searchText = undefined;
    getVisitorsPasses({ search: searchText });
  };

  return (
    <React.Fragment>
      <BreadCrumb title={title} pageTitle={title} />
      <FilterByVenueDD
        getItemsFn={(venueId) => {
          getVisitorsPasses({ venueId: venueId });
        }}
      />
      <DataTableBase
        tableTitle={title}
        data={visitorsPasses}
        columns={columns}
        loading={isLoading}
        // paginationTotalRows={total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        showActionButtons={false}
        showActionColumn={false}
        // onHeaderAddBtnClick={toggleCreateModal}
        // onHeaderDeleteBtnClick={() => {
        //   alert("Soon");
        // }}
        // onRowEditBtnClick={toggleEditModal}
        // onRowDeleteBtnClick={deleteTenant}
        onSearchIconClick={searchHandler}
      />
    </React.Fragment>
  );
}
