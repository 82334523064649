import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useHomeDashboardStore } from "../../stores/HomeDashboard";
import Widget from "./Widget";
import { t } from "i18next";

export default function DashboardOne() {
  const title=t("Dashboard") + t(" 1");
  document.title = title;

  const { data, getDashboardData } = useHomeDashboardStore((state) => state);

  useEffect(() => {
    getDashboardData();
  }, []);

  const widgetsData = [
    {
      label: "Total Requests Today",
      prefix: "",
      suffix: "",
      counter: data.totalRequestsToday || 0,
    },
    {
      label: "Total Requests Yesterday",
      prefix: "",
      suffix: "",
      counter: data.totalRequestsYesterday || 0,
    },
    {
      label: "Total Visitors Today",
      prefix: "",
      suffix: "",
      counter: data.totalVisitorsToday || 0,
    },
    {
      label: "Total Visitors Yesterday",
      prefix: "",
      suffix: "",
      counter: data.totalVisitorsYesterday || 0,
    },
    // {
    //   label: "Test2",
    //   percentage: "+16.24 %",
    //   percentageClass: "success",
    //   percentageIcon: "ri-arrow-right-up-line",
    //   linkText: "View net earnings",
    //   link: "earnings",
    //   icon: "bx bx-dollar-circle",
    //   iconClass: "success",
    //   decimals: 2,
    //   prefix: "$",
    //   suffix: "k",
    //   counter: "2009.25",
    //   iconBackgroundClass: "success",
    // },
    // {
    //   label: "Test3",
    //   percentage: "+16.24 %",
    //   percentageClass: "success",
    //   percentageIcon: "ri-arrow-right-up-line",
    //   linkText: "View net earnings",
    //   link: "earnings",
    //   icon: "bx bx-dollar-circle",
    //   iconClass: "success",
    //   decimals: 2,
    //   prefix: "$",
    //   suffix: "k",
    //   counter: "59.25",
    //   iconBackgroundClass: "success",
    // },
    // {
    //   label: "Test4",
    //   percentage: "+16.24 %",
    //   percentageClass: "success",
    //   percentageIcon: "ri-arrow-right-up-line",
    //   linkText: "View net earnings",
    //   link: "earnings",
    //   icon: "bx bx-dollar-circle",
    //   iconClass: "success",
    //   decimals: 2,
    //   prefix: "$",
    //   suffix: "k",
    //   counter: "759.25",
    //   iconBackgroundClass: "success",
    // },
  ];

  return (
    <React.Fragment>
      <BreadCrumb title={title} pageTitle={"Dashboards"} />
      <Row>
        {widgetsData.map((data, index) => (
          <Widget
            data={data}
            key={index}
            data-aos="zoom-in-up"
            data-aos-delay={index * 100}
          />
        ))}
      </Row>
    </React.Fragment>
  );
}
