import React from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DataTableBase from "../../Components/Common/DataTableBase/DataTableBase";
import { useVisitorsStore } from "../../stores";
import FilterByVenueDD from "../../Components/Common/DataTableBase/FilterByVenueDD";
import { t } from "i18next";

export default function Visitors() {
  const title = t("Visitors");

  document.title = title;

  const { visitors, getVisitors, isLoading } = useVisitorsStore(
    (state) => state
  );

  const onChangePage = (page) => {
    getVisitors(undefined, page);
  };
  let rowsPerPage = 10;
  const onChangeRowsPerPage = async (newPerPage, page) => {
    rowsPerPage = newPerPage;
    getVisitors(undefined, page, newPerPage);
  };

  const columns = [
    {
      name: "Code",
      width: "100px",
      selector: (row) => row.code,
      sortable: false,
      wrap: true,
    },
    {
      name: "Full Name",
      selector: (row) => row.fullName,
      sortable: true,
      wrap: true,
    },
    {
      name: "National ID",
      selector: (row) => row.nationalId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      wrap: true,
      width: "270px",
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      sortable: true,
      wrap: true,
    },
    {
      name: "Car Number",
      selector: (row) => row.carDetails.carNumber,
      sortable: false,
      wrap: true,
    },
    {
      name: "Car Model",
      selector: (row) => row.carDetails.carModel,
      sortable: true,
      wrap: true,
    },
  ];

  const searchHandler = (searchText) => {
    if (!searchText) searchText = undefined;
    getVisitors({search:searchText});
  };

  return (
    <React.Fragment>
      <BreadCrumb title={title} pageTitle={title} />
      <FilterByVenueDD
        getItemsFn={(venueId) => {
          getVisitors({ venueId: venueId });
        }}
      />
      <DataTableBase
        tableTitle={title}
        data={visitors}
        columns={columns}
        loading={isLoading}
        // paginationTotalRows={total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        showActionButtons={false}
        showActionColumn={false}
        // onHeaderAddBtnClick={toggleCreateModal}
        // onHeaderDeleteBtnClick={() => {
        //   alert("Soon");
        // }}
        // onRowEditBtnClick={toggleEditModal}
        // onRowDeleteBtnClick={deleteTenant}
        onSearchIconClick={searchHandler}
      />
    </React.Fragment>
  );
}
