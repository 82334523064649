/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTenantsStore } from "../../stores/Tenants";
import { useUnitsStore } from "../../stores/Units";
import { useVenuesStore } from "../../stores/Venues";
import { RenderFormikInput } from "../../Components/Common/Forms/FormikInputHelper";
import { Badge } from "reactstrap";
import { useConfirmDialogStore } from "./../../stores/Modal/ConfirmDialogStore";
import { t } from "i18next";

export function UpdateTenantModal({
  isOpen,
  toggle,
  tenantInfo,
  ...restProps
}) {
  // console.log(tenantInfo);
  const [tenant, setTenant] = useState(tenantInfo);
  // const [venues, setVenues] = useState([]);

  const { getTenant, updateTenant, suspendTenant, activateTenant, isLoading } =
    useTenantsStore((state) => state);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: tenant?.firstName || "",
      lastName: tenant?.lastName || "",
      phoneOne: tenant?.phoneOne || "",
      phoneTwo: tenant?.phoneTwo || "",
      carsDetails1: {
        _id: tenant?.carsDetails[0]?._id || "",
        carNumber: tenant?.carsDetails[0]?.carNumber || "",
        carModel: tenant?.carsDetails[0]?.carModel || "",
      },
      carsDetails2: {
        _id: tenant?.carsDetails[1]?._id || "",
        carNumber: tenant?.carsDetails[1]?.carNumber || "",
        carModel: tenant?.carsDetails[1]?.carModel || "",
      },
      venueId: tenant?.venueId._id || "",
      unitId: tenant?.unitId._id || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t("Required")),
      lastName: Yup.string().required(t("Required")),
      phoneOne: Yup.string().required(t("Required")),
      phoneTwo: Yup.string().required(t("Required")),
      carsDetails1: Yup.object().shape({
        _id: Yup.string(),
        carNumber: Yup.string(),
        carModel: Yup.string(),
      }),
      carsDetails2: Yup.object().shape({
        _id: Yup.string(),
        carNumber: Yup.string(),
        carModel: Yup.string(),
      }),
      venueId: Yup.string().required(t("Required")),
      unitId: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      const carsDetails = [values.carsDetails1, values.carsDetails2];
      const body = { ...values, carsDetails };
      delete body.carsDetails1;
      delete body.carsDetails2;
      const { result, message } = await updateTenant(tenantInfo?._id, body);
      if (result) {
        toggle();
      }
    },
  });

  const [selectedVenue, setSelectedVenue] = useState({
    label: tenantInfo?.venueId.name,
    value: tenantInfo?.venueId._id,
  });
  const [selectedUnit, setSelectedUnit] = useState({
    label: tenantInfo?.unitId.label,
    value: tenantInfo?.unitId._id,
  });

  const [units, setUnits] = useState([
    {
      value: formik.values.unitId._id,
      label: tenantInfo?.unitId.label,
    },
  ]);

  const asyncGetUnits = async (venueId) => {
    const units = await getUnits(venueId);
    const mappedUnits = units.map((el) => ({
      value: el._id,
      label: el.label,
    }));
    setUnits(mappedUnits);
    if (venueId !== tenantInfo.venueId._id) {
      setSelectedUnit("");
      formik.setFieldValue("unitId", "");
    }
    return mappedUnits;
  };

  const { getUserVenues, isLoading: venuesLoading } = useVenuesStore(
    (state) => state
  );

  const { getUnits, isLoading: unitsLoading } = useUnitsStore((state) => state);
  const fieldsToRender = [
    { fieldName: "firstName", label: "First Name" },
    { fieldName: "lastName", label: "Last Name" },
    { fieldName: "phoneOne", label: "First Phone Number" },
    { fieldName: "phoneTwo", label: "Second Phone Number" },
    { fieldName: "carsDetails1.carNumber", label: "First Car Number" },
    { fieldName: "carsDetails1.carModel", label: "First Car Model" },
    { fieldName: "carsDetails2.carNumber", label: "Second Car Number" },
    { fieldName: "carsDetails2.carModel", label: "Second Car Model" },
    {
      fieldName: "venueId",
      label: t("Select Venue"),
      type: "select",
      loadOptions: async () => {
        const venues = await getUserVenues();
        const mappedVenues = venues.map((el) => ({
          value: el._id,
          label: el.name,
        }));
        return mappedVenues;
      },
      value: selectedVenue,
      // options: venues,
      onChange: (option) => {
        setSelectedVenue(option);
      },
    },
    {
      fieldName: "unitId",
      label: "Select Unit",
      type: "select",
      options: units,
      value: selectedUnit,
      onChange: (unit) => {
        setSelectedUnit(unit);
      },
    },
  ];

  useEffect(() => {
    asyncGetUnits(selectedVenue.value);
    // setSelectedUnit("");
  }, [selectedVenue]);

  const { showConfirm } = useConfirmDialogStore((state) => state);

  const [tenantStatus, setTenantStatus] = useState(tenant?.status);
  const toggleStatus = async () => {
    showConfirm(async () => {
      if (tenantStatus === "Active") {
        const result = await suspendTenant(tenant._id);
        if (result) {
          setTenantStatus("Suspended");
        }
      } else if (tenantStatus === "Suspended") {
        const result = await activateTenant(tenant._id);
        if (result) {
          setTenantStatus("Active");
        }
      }
    });
  };

  return (
    <Modal keyboard={false} isOpen={isOpen} toggle={toggle} {...restProps}>
      <ModalHeader>{t("Edit Tenant Information")}</ModalHeader>

      <ModalBody>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-wrap justify-content-between gap-2"
        >
          {fieldsToRender.map((field) => (
            <div
              key={field.fieldName}
              className={`flex-fill ${field.fullWidth && "w-100"}`}
              style={field.fullWidth ? {} : { width: "48%", marginRight: 2 }}
            >
              {RenderFormikInput(formik, field)}
            </div>
          ))}
        </form>
        <Col className="d-flex align-items-center gap-2 mt-3">
          <Label className="m-0">{t('Status')}:</Label>
          <Badge
            className="px-4 py-2"
            color={
              tenantStatus === "Active"
                ? "success"
                : tenantStatus === "Suspended"
                ? "danger"
                : "warning"
            }
          >
            {tenantStatus}
          </Badge>
          {tenantStatus !== "Pending" && (
            <Button
              color={tenantStatus === "Active" ? "danger" : "success"}
              className="ms-auto"
              size="sm"
              onClick={toggleStatus}
            >
              {tenantStatus === "Active" ? t("Suspend" ): t("Activate")}
            </Button>
          )}
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          className="order-1"
          type="submit"
          onClick={() => {
            formik.submitForm();
          }}
          // disabled={!formik.dirty || !formik.isValid || isLoading}
        >
          {isLoading ?  t("Loading"):  t("Save")}
        </Button>
        {!isLoading && (
          <Button color="danger" onClick={toggle}>
             {t('Cancel')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
