/* eslint-disable no-debugger */
import { create } from "zustand";
import { axiosHelper } from "./helpers";

export const useMaintenanceStore = create((set, get) => ({
  records: [],
  recordsLoading: false,
  isError: false,
  error: undefined,
  isLoading: false,
  filters:null,
  // Methods

  getMaintenanceRecords: async (filters) => {
    set({ filters: filters });

    const body = {
      // search: search,
      // sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "",
      // dateTo: "",
      ...filters,
      page: filters?.page || 1,
      limit: filters?.limit || 10,
    };
    try {
      set({ recordsLoading: true });
      let response = await axiosHelper.post(
        "/system/manage-maintenanceRequests/get/maintenanceRequests",
        body
      );

      if (!response.result) {
        return;
      }

      set({ records: response.data });
      console.log(response.data[1]);
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      set({ recordsLoading: false });
    }
  },

  createMaintenanceRequest: async (requestData) => {
    const body = requestData;
    set({ isLoading: true });
    try {
      let response = await axiosHelper.post(
        "/system/manage-maintenanceRequests/create/maintenanceRequest",
        body
      );
      if (!response.result) {
        return;
      }
      get().getMaintenanceRecords(get().filters);
      return response;
    } catch (error) {
      return error.response;
    } finally {
      set({ isLoading: false });
    }
  },
  updateMaintenanceRequest: async (id, requestData) => {
    const body = requestData;
    set({ isLoading: true });
    try {
      let response = await axiosHelper.put(
        `/system/manage-maintenanceRequests/update/maintenanceRequest/${id}`,
        body
      );
      if (!response.result) {
        return;
      }
      get().getMaintenanceRecords(get().filters);
      return response;
    } catch (error) {
      return error.response;
    } finally {
      set({ isLoading: false });
    }
  },
  attendMaintenanceRequest: async (id) => {
    set({ isLoading: true });
    try {
      let response = await axiosHelper.put(
        `/system/manage-maintenanceRequests/attend/maintenanceRequest/${id}`
      );
      if (!response.result) {
        return;
      }
      get().getMaintenanceRecords(get().filters);
      return response.request;
    } catch (error) {
      return error.response;
    } finally {
      set({ isLoading: false });
    }
  },
  cancelMaintenanceRequest: async (id) => {
    set({ isLoading: true });
    try {
      let response = await axiosHelper.put(
        `/system/manage-maintenanceRequests/cancel/maintenanceRequest/${id}`
      );
      if (!response.result) {
        return;
      }
      get().getMaintenanceRecords(get().filters);
      return response.result;
    } catch (error) {
      return error.response;
    } finally {
      set({ isLoading: false });
    }
  },
  deleteMaintenanceRequest: async (id) => {
    set({ isLoading: true });
    try {
      let response = await axiosHelper.put(
        `/system/manage-maintenanceRequests/delete/maintenanceRequest/${id}`
      );
      if (!response.result) {
        return;
      }
      get().getMaintenanceRecords(get().filters);
      return response;
    } catch (error) {
      return error.response;
    } finally {
      set({ isLoading: false });
    }
  },

  maintenanceTypes: [],
  getMaintenanceTypes: async () => {
    const body = {
      // page: 1,
      // limit: 10,
      // sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "2023-01-31",
      // dateTo: "2023-03-31",
    };
    set({ isLoading: true });
    try {
      const response = await axiosHelper.post(
        "/system/manage-maintenanceTypes/get/maintenanceTypes"
      );
      if (!response.result) {
        return;
      }
      set({ facilityTypes: response.data });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
}));
