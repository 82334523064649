/* eslint-disable no-debugger */
import React, { useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DataTableBase from "../../../Components/Common/DataTableBase/DataTableBase";
import { useFacilitiesStore } from "../../../stores/Facilities";
import { CreateFacilityModal } from "./CreateFacilityModal";
import { EditFacilityModal } from "./EditFacilityModal";
import { ImagesCell } from "../../../Components/Common/DataTableBase/components/Cell-Formatting";
import FilterByVenueDD from "../../../Components/Common/DataTableBase/FilterByVenueDD";
import { t } from "i18next";

export default function Settings() {
  const title = t("Facilities Settings");

  document.title = title;

  const { facilities, getFacilities, deleteFacility, facilitiesLoading } =
    useFacilitiesStore((state) => state);
  const onChangePage = (page) => {
    getFacilities(undefined, page);
  };
  let rowsPerPage = 10;
  const onChangeRowsPerPage = async (newPerPage, page) => {
    rowsPerPage = newPerPage;
    getFacilities(undefined, page, newPerPage);
  };

  const columns = [
    {
      name: "Order",
      width: "90px",
      selector: (row) => row.order,
      sortable: true,
      wrap: true,
    },
    {
      name: "Code",
      width: "100px",
      selector: (row) => row.code,
      sortable: true,
      wrap: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) => row.typeId?.type,
      sortable: true,
      wrap: true,
    },
    {
      name: "Image",
      // selector: (row) => row.images.map((obj) => obj.small),
      cell: (row) => <ImagesCell images={[row.image]} />,
      width: "90px",
      sortable: false,
      wrap: true,
    },
    {
      name: "Rules",
      sortable: false,
      wrap: true,
      selector: (row) => row.rules.join(" , "),

      // cell: (row) => (
      //   <PhoneNumbersCe/ll first={row?.roles[0]} second={row?.roles[1]} />
      // ),
      style: {
        // textAlign: "center",
      },
    },
    // {
    //   name: "Booking Availability",
    //   selector: (row) => row.bookingAvailability,
    //   cell: (row) => <div>{row.bookingAvailability ? "Yes" : "No"}</div>,
    //   sortable: true,
    //   wrap: true,
    // },
    {
      name: "Max Allowed Slots",
      selector: (row) => row.maxAllowedSlots,
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: false,
      wrap: true,
    },
    {
      name: "Timing",
      // selector: (row) => row.timing,
      sortable: false,
      wrap: true,
      cell: (row) => row.timing,
    },
    {
      name: "Closing Period",
      // selector: (row) => row.closingPeriod,
      cell: (row) => (
        <div className="d-flex flex-column">
          <span>
            {row.closingPeriod.from &&
              new Date(row.closingPeriod.from).toLocaleDateString()}
          </span>
          {row.closingPeriod.from && row.closingPeriod.to && "to"}
          <span>
            {" "}
            {row.closingPeriod.to &&
              new Date(row.closingPeriod.to).toLocaleDateString()}
          </span>
        </div>
      ),
      sortable: false,
      wrap: true,
    },
  ];

  const [showCreateModal, setShowCreateModal] = useState(false);
  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };
  let [facilityInfo, setFacilityInfo] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const toggleEditModal = (row) => {
    setFacilityInfo(row);
    setShowEditModal(!showEditModal);
  };

  const searchHandler = (searchText) => {
    if (!searchText) searchText = undefined;
    getFacilities({search:searchText});
  };

  return (
    <React.Fragment>
      <BreadCrumb title={title} pageTitle={"Facilities"} />
      <FilterByVenueDD
        getItemsFn={(venueId) => {
          getFacilities({ venueId: venueId });
        }}
      />
      <DataTableBase
        tableTitle={title}
        data={facilities}
        columns={columns}
        loading={facilitiesLoading}
        // paginationTotalRows={total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onHeaderAddBtnClick={toggleCreateModal}
        onHeaderDeleteBtnClick={() => {
          alert("Soon");
        }}
        onRowEditBtnClick={toggleEditModal}
        onRowDeleteBtnClick={deleteFacility}
        onSearchIconClick={searchHandler}
      />
      {showCreateModal && (
        <CreateFacilityModal
          isOpen={showCreateModal}
          toggle={toggleCreateModal}
          backdrop="static"
          onClosed={(x)=>{console.log(x)}}
        />
      )}
      {showEditModal && (
        <EditFacilityModal
          isOpen={showEditModal}
          toggle={toggleEditModal}
          backdrop="static"
          facilityInfo={facilityInfo}
        />
      )}
    </React.Fragment>
  );
}
