import Tenants from "./Tenants";
import Bookings from "./Facilities/Bookings";
import Settings from "./Facilities/Settings/Settings";
import Staffs from "./Staffs";
import Maintenance from "./Maintenance";
import Visitors from "./Visitors";
import VisitorsPasses from "./VisitorsPasses";
import * as Dashboards from "./Dashboards";

export {
  Tenants,
  Dashboards,
  Bookings,
  Settings,
  Staffs,
  Maintenance,
  VisitorsPasses,
  Visitors,
};
