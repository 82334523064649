import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboards, setIsDashboards] = useState(false);
  const [isTenants, setIsTenants] = useState(false);
  const [isFacilities, setIsFacilities] = useState(false);
  const [isVisitors, setIsVisitors] = useState(false);
  const [isVisitorsPasses, setIsVisitorsPasses] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [isStaffs, setIsStaffs] = useState(false);
  const [isVenues, setIsVenues] = useState(false);

  const [isCurrentState, setIsCurrentState] = useState("Dashboards");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    if (isCurrentState !== "Dashboards") {
      setIsDashboards(false);
    }
    if (isCurrentState !== "Tenants") {
      setIsTenants(false);
    }
    if (isCurrentState !== "Facilities") {
      setIsFacilities(false);
    }
    if (isCurrentState !== "Visitors") {
      setIsVisitors(false);
    }
    if (isCurrentState !== "VisitorsPasses") {
      setIsVisitorsPasses(false);
    }
    if (isCurrentState !== "Maintenance") {
      setIsMaintenance(false);
    }
    if (isCurrentState !== "Staffs") {
      setIsStaffs(false);
    }
    if (isCurrentState !== "Venues") {
      setIsVenues(false);
    }
  }, [isCurrentState, history]);

  const menuItems = [
    {
      label: t("Menu"),
      isHeader: true,
    },
    {
      id: "dashboards",
      label: "Dashboards",
      icon: "ri-dashboard-2-line",
      link: "",
      stateVariables: isDashboards,
      click: function (e) {
        e.preventDefault();
        setIsDashboards(!isDashboards);
        setIsCurrentState("Dashboards");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "dashboard1",
          label: t("Dashboard")+"1",
          link: "/dashboards/1",
          parentId: "dashboard",
        },
      ],
    },

    {
      id: "tenants",
      label: "Tenants",
      icon: "ri-user-line",
      link: "",
      stateVariables: isTenants,
      click: function (e) {
        e.preventDefault();
        setIsTenants(!isTenants);
        setIsCurrentState("Tenants");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "tenants-1",
          label: "Tenants",
          link: "/tenants",
          parentId: "tenants",
        },
      ],
    },
    {
      id: "facilities",
      label: "Facilities",
      icon: "ri-calendar-2-line",
      link: "",
      stateVariables: isFacilities,
      click: function (e) {
        e.preventDefault();
        setIsFacilities(!isFacilities);
        setIsCurrentState("Facilities");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "bookings",
          label: "Bookings",
          link: "/facilities/bookings",
          parentId: "facilities",
        },
        {
          id: "settings",
          label: "Settings",
          link: "/facilities/settings",
          parentId: "facilities",
        },
      ],
    },

    {
      id: "visitors",
      label: "Visitors",
      icon: "ri-group-line",
      link: "",
      stateVariables: isVisitors,
      click: function (e) {
        e.preventDefault();
        setIsVisitors(!isVisitors);
        setIsCurrentState("Visitors");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "visitors-1",
          label: "Visitors",
          link: "/visitors",
          parentId: "visitors",
        },
        {
          id: "visitors-passes",
          label: "Visitors Passes",
          link: "/visitors-passes",
          parentId: "visitors",
        },
      ],
    },
    // {
    //   id: "visitorsPasses",
    //   label: "Visitors Passes",
    //   icon: "ri-group-line",
    //   link: "/visitorsPasses",
    //   stateVariables: isVisitorsPasses,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsVisitors(!isVisitorsPasses);
    //     setIsCurrentState("VisitorsPasses");
    //     updateIconSidebar(e);
    //   },
    // },
    {
      id: "maintenance",
      label: "Maintenance",
      icon: "ri-settings-6-line",
      link: "",
      stateVariables: isMaintenance,
      click: function (e) {
        e.preventDefault();
        setIsMaintenance(!isMaintenance);
        setIsCurrentState("Maintenance");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "maintenance-1",
          label: "Maintenance",
          link: "/maintenance",
          parentId: "maintenance",
        },
      ],
    },
    {
      id: "staffs",
      label: "Staffs",
      icon: "ri-user-star-line",
      link: "/",
      stateVariables: isStaffs,
      click: function (e) {
        e.preventDefault();
        setIsStaffs(!isStaffs);
        setIsCurrentState("Staffs");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "staffs-1",
          label: "Staffs",
          link: "/staffs",
          parentId: "staffs",
        },
      ],
    },
    {
      id: "venues",
      label: "Venues",
      icon: "ri-command-line",
      link: "/",
      stateVariables: isVenues,
      click: function (e) {
        e.preventDefault();
        setIsVenues(!isVenues);
        setIsCurrentState("Venues");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "venues-1",
          label: "Venues",
          link: "/venues",
          parentId: "venues",
        },
      ],
    },
  ];

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
