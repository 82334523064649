import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Badge,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMaintenanceStore } from "../../stores";
import { RenderFormikInput } from "../../Components/Common/Forms/FormikInputHelper";
import ImageUploader from "./ImageUploader";
import { useConfirmDialogStore } from "../../stores/Modal/ConfirmDialogStore";
import { t } from "i18next";

export function UpdateMaintenanceModal({
  isOpen,
  toggle,
  onClosed,
  maintenanceInfo,
  ...restProps
}) {
  const formik = useFormik({
    initialValues: {
      description: maintenanceInfo.description,
      type: maintenanceInfo.type._id,
      images: maintenanceInfo.images,
    },
    validationSchema: Yup.object({
      description: Yup.string().required(t("Required")),
      type: Yup.string().required(t("Required")),
      images: Yup.array(),
    }),
    onSubmit: async (values) => {
      // console.log(values);
      const { result, message } = await updateMaintenanceRequest(
        maintenanceInfo._id,
        values
      );
      if (result) {
        toggle();
      }
    },
  });

  // useEffect(() => {
  //   const getTypes = async () => {
  //     const types = await getMaintenanceTypes();
  //     const mappedMaintenanceTypes = types.map((el) => ({
  //       value: el._id,
  //       label: el.type,
  //     }));
  //     setTypeOptions(mappedMaintenanceTypes);
  //     formik.setFieldValue("type", maintenanceInfo.type._id);
  //   };

  //   getTypes();
  // }, []);

  const [typeOptions, setTypeOptions] = useState([]);
  const fieldsToRender = [
    { fieldName: "description", label: "Description" },
    {
      fieldName: "type",
      label: "Type",
      type: "select",
      // options: typeOptions,
      loadOptions: async () => {
        const maintenanceTypes = await getMaintenanceTypes();
        const mappedMaintenanceTypes = maintenanceTypes.map((el) => ({
          value: el._id,
          label: el.type,
        }));
        return mappedMaintenanceTypes;
      },
      defaultValue: {
        value: formik.values.type._id,
        label: maintenanceInfo.type.type,
      },
    },
  ];

  const {
    updateMaintenanceRequest,
    getMaintenanceTypes,
    isLoading,
    attendMaintenanceRequest,
    cancelMaintenanceRequest,
  } = useMaintenanceStore((state) => state);

  const [images, setImages] = useState(maintenanceInfo.images);

  const imageUploaderHandler = (img) => {
    if (!img) return;
    const newImgs = [...images, img];
    setImages(newImgs);
    formik.setFieldValue("images", newImgs);
  };

  const deleteImage = (index) => {
    const imgs = images.filter((el, i) => i !== index);
    setImages(imgs);
    formik.setFieldValue("images", imgs);
  };

  const [status, setStatus] = useState(maintenanceInfo?.status);
  const { showConfirm } = useConfirmDialogStore((state) => state);

  const attendRequest = async () => {
    showConfirm(async () => {
      const result = await attendMaintenanceRequest(maintenanceInfo._id);
      if (result) {
        setStatus("Attended");
      }
    });
  };
  const cancelRequest = async () => {
    showConfirm(async () => {
      const result = await cancelMaintenanceRequest(maintenanceInfo._id);
      if (result) {
        setStatus("Cancelled");
      }
    });
  };

  return (
    <Modal keyboard={false} isOpen={isOpen} toggle={toggle} onClosed={onClosed} {...restProps}>
      <ModalHeader>Update Maintenance Request</ModalHeader>
      <ModalBody>
        <Col className="d-flex align-items-center gap-2 mb-4">
          <Label className="m-0">Status:</Label>
          <Badge
            className="px-4 py-2"
            color={
              status === "Submitted"
                ? "warning"
                : status === "Attended"
                ? "success"
                : "danger"
            }
          >
            {status}
          </Badge>
          <div className="ms-auto d-flex gap-2">
            {status === "Submitted" && (
              <Button color={"success"} size="sm" onClick={attendRequest}>
                Attend
              </Button>
            )}
            {status === "Submitted" && (
              <Button color={"danger"} size="sm" onClick={cancelRequest}>
                {t('Cancel')}
              </Button>
            )}
          </div>
        </Col>

        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column gap-3"
        >
          {fieldsToRender.map((field) => (
            <Col key={field.fieldName}>{RenderFormikInput(formik, field)}</Col>
          ))}
          <ImageUploader
            getImagesLinks={imageUploaderHandler}
            // cb={resetImage}
          />{" "}
          {/* {images.length !== 0 && images.length < 2 && (
            <span className="text-danger">
              At least two photos should be uploaded.
            </span>
          )} */}
        </form>
        <div className="d-flex gap-2 mt-3">
          {images.map((el, index) => (
            <div
              className="position-relative d-flex flex-column justify-content-center align-items-center gap-2 text-center"
              key={index}
            >
              <img src={el.small} alt="uploaded-img" height={"50"} />
              <span
                className="text-white bg-danger rounded-circle  d-flex align-items-center justify-content-center"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => {
                  deleteImage(index);
                }}
              >
                x
              </span>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          className="order-1"
          type="submit"
          onClick={() => {
            formik.submitForm();
          }}
          disabled={!formik.dirty || !formik.isValid || isLoading}
        >
          {isLoading ?  t("Loading"):  t("Save")}
        </Button>
        {!isLoading && (
          <Button color="danger" onClick={toggle}>
            {t('Cancel')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
