import { create } from "zustand";
import { axiosHelper } from "./helpers";

export const useVisitorsStore = create((set, get) => ({
  visitors: [],
  isLoading: false,
  isError: false,
  error: undefined,
  // Methods
  getVisitors: async (filters) => {
    const body = {
      // search: search,
      // sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "",
      // dateTo: "",
      ...filters,
      page: filters?.page || 1,
      limit: filters?.limit || 10,
    };

    try {
      set({ isLoading: true });
      let response = await axiosHelper.post(
        "/system/manage-visitors/get/visitors",
        body
      );

      if (!response.result) {
        return;
      }
      set({ visitors: response.data });
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
}));
