import { create } from "zustand";

export const useConfirmDialogStore = create((set, get) => ({
  isOpen: false,
  isConfirmed: false,
  confirmCB: null,
  cancelCB: null,

  toggle: () => {
    set((state) => ({ isOpen: !state.isOpen, isConfirmed: false }));
    get().cancelCB();
  },

  showConfirm: (confirmCB = () => {}, cancelCB = () => {}) => {
    set({ isOpen: true, confirmCB: confirmCB, cancelCB: cancelCB });
  },
  confirm: () => {
    set((state) => ({ isOpen: false, isConfirmed: true }));
    get().confirmCB();
  },
}));
