import { create } from "zustand";
import { axiosHelper } from "./helpers";

export const useVisitorsPassesStore = create((set, get) => ({
  visitorsPasses: [],
  isLoading: false,
  isError: false,
  error: undefined,
  // Methods
  getVisitorsPasses: async (filters) => {
    const body = {
      // search: search,
      // sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "",
      // dateTo: "",
      ...filters,
      page: filters?.page || 1,
      limit: filters?.limit || 10,
    };

    try {
      set({ isLoading: true });
      let response = await axiosHelper.post(
        "/system/manage-visitorsPasses/get/visitorsPasses",
        body
      );

      if (!response.result) {
        return;
      }
      set({ visitorsPasses: response.data });
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
}));
