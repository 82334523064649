import React, { useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMaintenanceStore } from "../../stores";
import { RenderFormikInput } from "../../Components/Common/Forms/FormikInputHelper";
import ImageUploader from "./ImageUploader";
import { t } from "i18next";

export function CreateMaintenanceModal({
  isOpen,
  toggle,
  onClosed,
  ...restProps
}) {
  const formik = useFormik({
    initialValues: {
      description: "",
      type: "",
      images: [],
    },
    validationSchema: Yup.object({
      description: Yup.string().required(t("Required")),
      type: Yup.string().required(t("Required")),
      // images: Yup.array().min(2).required("Required, at least Two images."),
    }),
    onSubmit: async (values) => {
      // console.log(values);
      const { result, message } = await createMaintenanceRequest(values);
      if (result) {
        toggle();
      }
    },
  });

  const fieldsToRender = [
    { fieldName: "description", label: "Description" },
    {
      fieldName: "type",
      label: "Type",
      type: "select",
      loadOptions: async () => {
        const maintenanceTypes = await getMaintenanceTypes();
        const mappedMaintenanceTypes = maintenanceTypes.map((el) => ({
          value: el._id,
          label: el.type,
        }));
        return mappedMaintenanceTypes;
      },
    },
  ];

  const { createMaintenanceRequest, getMaintenanceTypes, isLoading } =
    useMaintenanceStore((state) => state);

  const [images, setImages] = useState([]);

  const imageUploaderHandler = (img) => {
    if (!img) return;
    const newImgs = [...images, img];
    setImages(newImgs);
    formik.setFieldValue("images", newImgs);
  };

  const deleteImage = (index) => {
    const imgs = images.filter((el, i) => i !== index);
    setImages(imgs);
    formik.setFieldValue("images", imgs);
  };

  return (
    <Modal keyboard={false} isOpen={isOpen} toggle={toggle} onClosed={onClosed} {...restProps}>
      <ModalHeader>{t('Create Maintenance Request')}</ModalHeader>
      <ModalBody>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column gap-3"
        >
          {fieldsToRender.map((field) => (
            <Col key={field.fieldName}>{RenderFormikInput(formik, field)}</Col>
          ))}

          <ImageUploader
            getImagesLinks={imageUploaderHandler}
            // cb={resetImage}
          />
          {/* {images.length !== 0 && images.length < 2 && (
            <span className="text-danger">
              At least two photos should be uploaded.
            </span>
          )} */}
        </form>
        <div className="d-flex gap-2 mt-3">
          {images.map((el, index) => (
            <div
              className="position-relative d-flex flex-column justify-content-center align-items-center gap-2 text-center"
              key={index}
            >
              <img src={el.small} alt="uploaded-img" height={"50"} />
              <span
                className="text-white bg-danger rounded-circle  d-flex align-items-center justify-content-center"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => {
                  deleteImage(index);
                }}
              >
                x
              </span>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          className="order-1"
          type="submit"
          onClick={() => {
            formik.submitForm();
          }}
          disabled={!formik.dirty || !formik.isValid || isLoading}
        >
          {isLoading ?  t("Loading"):  t("Save")}
        </Button>
        {!isLoading && (
          <Button color="danger" onClick={toggle}>
             {t('Cancel')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
