import React, { useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RenderFormikInput } from "../../../Components/Common/Forms/FormikInputHelper";
import { useFacilitiesStore } from "../../../stores/Facilities";
import ImageUploader from "./ImageUploader";
import { useVenuesStore } from "../../../stores/Venues";
import { t } from "i18next";

export function CreateFacilityModal({
  isOpen,
  toggle,
  onClosed,
  ...restProps
}) {
  const formik = useFormik({
    initialValues: {
      order: "",
      title: "",
      titleAr: "",
      description: "",
      descriptionAr: "",
      timing: "",
      timingAr: "",
      // bookingAvailability: undefined,
      typeId: "",
      venueId: "",
      rules: [],
      rulesAr: [],
      closingPeriod: {
        from: "",
        to: "",
      },
      maxAllowedSlots: 1,
      maxBookingDays: 7,
      weekCalender: {
        timeForBooking: "1hr",
        from: "07:00",
        to: "23:00",
      },
      image: {
        normal: "",
        small: "",
      },
    },
    validationSchema: Yup.object({
      order: Yup.number().min(0, `${t("Can't be less than")} 0`),
      title: Yup.string().required(t("Required")),
      titleAr: Yup.string().required(t("Required")),
      description: Yup.string().required(t("Required")),
      descriptionAr: Yup.string().required(t("Required")),
      timing: Yup.string().required(t("Required")),
      timingAr: Yup.string().required(t("Required")),
      // bookingAvailability: Yup.boolean().required(t("Required")),
      typeId: Yup.string().required(t("Required")),
      venueId: Yup.string().required(t("Required")),
      rules: Yup.array().min(1, t("Required. Minimum one rule ")),
      rulesAr: Yup.array().min(1, t("Required. Minimum one rule ")),
      closingPeriod: Yup.object({
        from: Yup.date().optional(),
        to: Yup.date().optional(),
      }).optional(),
      maxAllowedSlots: Yup.number()
        .min(1, `${t("Can't be less than")} 1`)
        .optional(),
      maxBookingDays: Yup.number()
        .min(1, `${t("Can't be less than")} 1`)
        .optional(),
      weekCalender: Yup.object({
        timeForBooking: Yup.string()
          .oneOf(["30min", "1hr", "1.5hr"])
          .required(t("Required")),
        from: Yup.string().required(t("Required")),
        to: Yup.string().required(t("Required")),
      }).required(t("Required")),
      image: Yup.object({
        normal: Yup.string().required(t("Required")),
        small: Yup.string().required(t("Required")),
      }).required(t("Required")),
    }),
    onSubmit: async (values) => {
      // console.log(values);
      const { result, message } = await createFacility(values);
      if (result) toggle();
    },
  });

  const [venueValue, setVenueValue] = useState(null);

  const fieldsToRender = [
    { fieldName: "order", type: "number", label: "Order" },
    {
      fieldName: "maxBookingDays",
      type: "number",
      label: "Max Booking Days",
    },
    { fieldName: "title", type: "text", label: "Title in English" },
    { fieldName: "titleAr", type: "text", label: "Title in Arabic" },
    {
      fieldName: "description",
      type: "textarea",
      label: "Description in English",
    },
    {
      fieldName: "descriptionAr",
      type: "textarea",
      label: "Description in Arabic",
    },
    { fieldName: "timing", type: "text", label: "Timing in English" },
    { fieldName: "timingAr", type: "text", label: "Timing in Arabic" },

    {
      fieldName: "maxAllowedSlots",
      type: "number",
      label: "Max Allowed Slots",
      className:"col-12"
    },

    {
      fieldName: "rules",
      type: "chips",
      label: "Rules in English",
      options: [
        // { value: "No Smoking", label: "No Smoking" },
        // { value: "No Loud voice", label: "No Loud voice" },
        // { value: "vanilla", label: "Vanilla" },
      ],
      className: "col-12",
    },
    {
      fieldName: "rulesAr",
      type: "chips",
      label: "Rules in Arabic",
      options: [
        // { value: "No Smoking", label: "No Smoking" },
        // { value: "No Loud voice", label: "No Loud voice" },
        // { value: "vanilla", label: "Vanilla" },
      ],
      className: "col-12",
    },
    {
      fieldName: "closingPeriod.from",
      type: "date",
      label: "Closing Period from",
    },
    {
      fieldName: "closingPeriod.to",
      type: "date",
      label: "Closing Period to",
    },
    {
      fieldName: "typeId",
      type: "select",
      label: "Facility Type",
      loadOptions: async () => {
        const facilityTypes = await getFacilityTypes();
        const mappedFacilityTypes = facilityTypes.map((el) => ({
          value: el._id,
          label: el.type,
        }));
        return mappedFacilityTypes;
      },
    },

    {
      fieldName: "venueId",
      type: "select",
      label: "Select Venue",
      loadOptions: async () => {
        const venues = await getUserVenues();
        const mapped = venues.map((el) => ({
          value: el._id,
          label: el.name,
        }));
        const firstItem = mapped[0];
        setFirstItem(firstItem);
        return mapped;
      },
      fullWidth: true,
      value: venueValue,
      onChange: (venue) => {
        setVenueValue(venue);
      },
    },
  ];
  const setFirstItem = (firstItem) => {
    if (!firstItem) return;
    setVenueValue(firstItem);
    formik.setFieldValue("venueId", firstItem.value);
  };

  const [bookingTime, setBookingTime] = useState({
    value: "1hr",
    label: t("1hr"),
  });
  const timeForBookingFields = [
    {
      fieldName: "weekCalender.timeForBooking",
      type: "select",
      label: "Booking Time",
      options: [
        {
          value: "30min",
          label: "30min",
        },
        {
          value: "1hr",
          label: "1hr",
        },
        {
          value: "1.5hr",
          label: "1.5hr",
        },
      ],
      value: bookingTime,
      onChange: (option) => {
        setBookingTime(option);
      },
    },
    {
      fieldName: "weekCalender.from",
      type: "time",
      label: "From",
    },
    {
      fieldName: "weekCalender.to",
      type: "time",
      label: "To",
    },
  ];

  const { createFacility, getFacilityTypes, isLoading } = useFacilitiesStore(
    (state) => state
  );
  const { getUserVenues } = useVenuesStore((state) => state);

  const imageUploaderHandler = (img) => {
    if (!img) return;

    formik.setFieldValue("image", img);
  };

  const resetImage = () => {
    formik.setFieldValue("image", null);
  };

  return (
    <Modal
      keyboard={false}
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
      {...restProps}
    >
      <ModalHeader>{t("Add New Facility")}</ModalHeader>
      <ModalBody>
        <form
          onSubmit={formik.handleSubmit}
          // className="d-flex flex-wrap justify-content-between gap-2"
          className="row"
        >
          {/* {fieldsToRender.map((field) => (
            <Col key={field.fieldName}>{RenderFormikInput(formik, field)}</Col>
          ))} */}
          {fieldsToRender.map((field) => (
            <div
              key={field.fieldName}
              // className={`flex-fill ${field.fullWidth && "w-100"}`}
              className={field.className || "col-6"}
            >
              {RenderFormikInput(formik, field)}
            </div>
          ))}

          <Row>
            {timeForBookingFields.map((field) => (
              <Col sm="4" key={field.fieldName}>
                {RenderFormikInput(formik, field)}
              </Col>
            ))}
          </Row>

          <ImageUploader
            getImagesLinks={imageUploaderHandler}
            cb={resetImage}
            className="mt-3"
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          className="order-1"
          type="submit"
          onClick={() => {
            formik.submitForm();
          }}
          disabled={!formik.dirty || !formik.isValid || isLoading}
        >
          {isLoading ? t("Loading") : t("Save")}
        </Button>
        {!isLoading && (
          <Button color="danger" onClick={toggle}>
            {t("Cancel")}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
