import React, { useEffect, useState } from "react";
import { Card, CardBody, Label } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import FacilitiesCalendar from "./Calendar/Calendar";
import { useVenuesStore } from "../../stores/Venues";
import { reactSelectStyling } from "../../Components/Common/Forms/FormikInputHelper";
import { useFormik } from "formik";
import { useFacilitiesStore } from "../../stores/Facilities";
import AsyncSelect from "react-select/async";
import ReactSelect from "react-select";
import { t } from "i18next";

export default function Bookings(props) {
  const title = t("Bookings");
  document.title = title;
  const [facilityId, setFacilityId] = useState();
  const { getUserVenues } = useVenuesStore((state) => state);
  const { getFacilitiesByVenue } = useFacilitiesStore((state) => state);
  const [facilitiesItems, setFacilitiesItems] = useState([]);

  const [venueItem, setVenueItem] = useState(null);
  const [facilityItem, setFacilityItem] = useState(null);

  const loadVenuesOptions = async () => {
    const venues = await getUserVenues();
    const mapped = venues.map((el) => ({
      value: el._id,
      label: el.name,
    }));

    const firstItem = mapped.length > 0 ? mapped[0] : null;
    // debugger;
    setVenueItem(firstItem);

    return mapped;
  };

  useEffect(() => {
    if (!venueItem?.value) return;
    getFacilitiesByVenue(venueItem.value).then((data) => {
      const mapped = data.map((el) => ({
        value: el._id,
        label: el.title,
      }));
      setFacilitiesItems(mapped);
      const firstItem = mapped[0];
      if (firstItem) {
        setFacilityItem(firstItem);
      }
    });
  }, [venueItem]);

  useEffect(() => {
    if (!facilityItem?.value) return;
    setFacilityId(facilityItem.value);
  }, [facilityItem]);

  return (
    <React.Fragment>
      <BreadCrumb title={title} pageTitle={title} />
      <div className="d-flex align-items-center gap-2 mb-2 w-100">
        <div className="w-25" style={{ zIndex: 50 }}>
          <Label className="form-label m-0">{t("Select Venue")}</Label>
          <AsyncSelect
            placeholder={t("Search")}
            cacheOptions
            loadOptions={loadVenuesOptions}
            {...reactSelectStyling}
            defaultOptions
            onChange={(value) => {
              setVenueItem(value);
            }}
            value={venueItem}
          />
        </div>
        <div className="w-25" style={{ zIndex: 50 }}>
          <Label className="form-label m-0">{t("Select Facility")}</Label>
          <ReactSelect
            {...reactSelectStyling}
            options={facilitiesItems}
            value={facilityItem}
            onChange={(value) => {
              setFacilityItem(value);
            }}
            placeholder={t("Search")}
          />
        </div>
      </div>
      {facilityId && (
        <Card>
          <CardBody>
            <FacilitiesCalendar facilityId={facilityId} />
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
}
