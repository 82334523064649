import React, { useState } from "react";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RenderFormikInput } from "../../Components/Common/Forms/FormikInputHelper";
import { ImageUploader } from "./ImageUploader";
import { useVenuesStore } from "./../../stores/Venues";
import { t } from "i18next";

export function CreateVenueModal({ isOpen, toggle, onClosed, ...restProps }) {
  const formik = useFormik({
    initialValues: {
      name: "",
      street: "",
      address: "",
      zone: "",
      building: "",
      socialMediaAccounts: {
        facebook: "",
        twitter: "",
        instagram: "",
      },
      domainName: "",
      publicContactNumber: "",
      publicEmail: "",
      status: "",
      image: {
        normal: "",
        small: "",
      },
      logo: {
        normal: "",
        small: "",
      },
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Required")),
      street: Yup.string().required(t("Required")),
      address: Yup.string().required(t("Required")),
      zone: Yup.string().required(t("Required")),
      building: Yup.string().required(t("Required")),
      socialMediaAccounts: Yup.object({
        facebook: Yup.string(),
        twitter: Yup.string(),
        instagram: Yup.string(),
      }).optional(),
      domainName: Yup.string().required(t("Required")),
      publicContactNumber: Yup.string().required(t("Required")),
      publicEmail: Yup.string()
        .email("Invalid Email Address")
        .required(t("Required")),
      status: Yup.string().required(t("Required")),
      logo: Yup.object({
        normal: Yup.string().required(t("Required")),
        small: Yup.string().required(t("Required")),
      }).required(t("Required")),
      image: Yup.object({
        normal: Yup.string().required(t("Required")),
        small: Yup.string().required(t("Required")),
      }).required(t("Required")),
    }),
    onSubmit: async (values) => {
      // console.log(values);
      const { result, message } = await createVenue(values);
      if (result) toggle();
    },
  });

  const fieldsToRender = [
    { fieldName: "name", type: "text", label: "Name" },
    { fieldName: "street", type: "text", label: "Street" },
    { fieldName: "address", type: "text", label: "Address" },
    { fieldName: "zone", type: "text", label: "Zone" },
    { fieldName: "building", type: "text", label: "Building" },
    { fieldName: "domainName", type: "text", label: "Domain Name" },
    {
      fieldName: "publicContactNumber",
      type: "text",
      label: "Public Contact Number",
    },
    { fieldName: "publicEmail", type: "text", label: "Public Email" },
    {
      fieldName: "socialMediaAccounts.facebook",
      type: "text",
      label: "Facebook",
    },
    {
      fieldName: "socialMediaAccounts.twitter",
      type: "text",
      label: "Twitter",
    },
    {
      fieldName: "socialMediaAccounts.instagram",
      type: "text",
      label: "Instagram",
    },
    {
      fieldName: "status",
      type: "select",
      label: "Status",
      options: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "InActive" },
      ],
    },
  ];

  const { createVenue, isLoading } = useVenuesStore((state) => state);

  const imageUploaderHandler = (img, fieldName) => {
    if (!img) return;
    formik.setFieldValue(fieldName, img);
  };

  const resetImage = (fieldName) => {
    formik.setFieldValue(fieldName, null);
  };

  return (
    <Modal keyboard={false} isOpen={isOpen} toggle={toggle} onClosed={onClosed}  {...restProps}>
      <ModalHeader>{t("Add New Venue")}</ModalHeader>
      <ModalBody>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-wrap justify-content-between gap-2"
        >
          {fieldsToRender.map((field) => (
            <div
              key={field.fieldName}
              className={`flex-fill ${field.fullWidth && "w-100"}`}
            >
              {RenderFormikInput(formik, field)}
            </div>
          ))}
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column">
            <Label>{t('Image')}</Label>
              <ImageUploader
                getImagesLinks={(img) => {
                  imageUploaderHandler(img, "logo");
                }}
                cb={() => {
                  resetImage("logo");
                }}
              />
            </div>
            <div className="d-flex flex-column">
              <Label>{t('Logo')}</Label>
              <ImageUploader
                getImagesLinks={(img) => {
                  imageUploaderHandler(img, "image");
                }}
                cb={() => {
                  resetImage("image");
                }}
              />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          className="order-1"
          type="submit"
          onClick={() => {
            // console.log(formik.values);
            formik.submitForm();
          }}
          disabled={!formik.dirty || !formik.isValid || isLoading}
        >
          {isLoading ?  t("Loading"):  t("Save")}
        </Button>
        {!isLoading && (
          <Button color="danger" onClick={toggle}>
            {t('Cancel')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
