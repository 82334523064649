import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RenderFormikInput } from "../../../Components/Common/Forms/FormikInputHelper";
import { useFacilitiesStore } from "../../../stores/Facilities";
import { useVenuesStore } from "../../../stores/Venues";
import ImageUploader from "./ImageUploader";
import { t } from "i18next";

export function EditFacilityModal(props) {
  const { getFacility } = useFacilitiesStore((state) => state);
  const [facilityDetails, setFacilityDetails] = useState(null);

  useEffect(() => {
    async function getFacilityDetails() {
      const _facility = await getFacility(props.facilityInfo._id);
      setFacilityDetails(_facility);
      // console.log(_facility);
    }
    getFacilityDetails();
  }, []);

  if (!facilityDetails) {
    return "Loading";
  }

  return (
    <EditFacilityModalContent facilityDetails={facilityDetails} {...props} />
  );
}

const EditFacilityModalContent = ({
  isOpen,
  toggle,
  onClosed,
  facilityDetails,
  facilityInfo: facilityInfoInputFromTable,
  ...restProps
}) => {
  const formik = useFormik({
    initialValues: {
      order: facilityDetails?.order || "",
      title: facilityDetails?.title || "",
      titleAr: facilityDetails?.titleAr || "",
      description: facilityDetails?.description || "",
      descriptionAr: facilityDetails?.descriptionAr || "",
      timing: facilityDetails?.timing || "",
      timingAr: facilityDetails?.timingAr || "",
      typeId: facilityDetails?.typeId._id || "",
      venueId: facilityDetails?.venueId || "",
      rules: facilityDetails?.rules || [],
      rulesAr: facilityDetails?.rulesAr || [],
      closingPeriod: {
        from: facilityDetails?.closingPeriod.from
          ? new Date(facilityDetails?.closingPeriod.from)
              ?.toISOString()
              .split("T")[0]
          : "",
        to: facilityDetails?.closingPeriod.to
          ? new Date(facilityDetails?.closingPeriod.to)
              ?.toISOString()
              .split("T")[0]
          : "",
      },
      maxAllowedSlots: facilityDetails?.maxAllowedSlots || "",
      maxBookingDays: facilityDetails?.maxBookingDays || "",
      weekCalender: {
        timeForBooking: facilityDetails?.weekCalender.timeForBooking || "",
      },
      image: {
        normal: facilityDetails?.image.normal || "",
        small: facilityDetails?.image.small || "",
      },
    },
    validationSchema: Yup.object({
      order: Yup.number().min(0),
      title: Yup.string().required(t("Required")),
      titleAr: Yup.string().required(t("Required")),
      description: Yup.string().required(t("Required")),
      descriptionAr: Yup.string().required(t("Required")),
      timing: Yup.string().required(t("Required")),
      timingAr: Yup.string().required(t("Required")),
      typeId: Yup.string().required(t("Required")),
      venueId: Yup.string().required(t("Required")),
      rules: Yup.array().min(1, "Required. Minimum one rule "),
      rulesAr: Yup.array().min(1, "Required. Minimum one rule "),
      closingPeriod: Yup.object({
        from: Yup.date().optional(),
        to: Yup.date().optional(),
      }).optional(),
      maxAllowedSlots: Yup.number().min(1, "Can't be less than 1").optional(),
      maxBookingDays: Yup.number().min(1, "Can't be less than 1").optional(),
      weekCalender: Yup.object({
        timeForBooking: Yup.string()
          .oneOf(["30min", "1hr", "1.5hr"])
          .required(t("Required")),
      }).required(t("Required")),
      image: Yup.object({
        normal: Yup.string().required(t("Required")),
        small: Yup.string().required(t("Required")),
      }).required(t("Required")),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      // console.log(values.typeId);
      // console.log(await updateFacility(values));
      const { result, message } = await updateFacility(
        facilityDetails._id,
        values
      );
      if (result) toggle();
    },
  });

  const fieldsToRender = [
    { fieldName: "order", type: "number", label: "Order" },
    {
      fieldName: "maxBookingDays",
      type: "number",
      label: "Max Booking Days",
    },

    { fieldName: "title", type: "text", label: "Title in English" },
    { fieldName: "titleAr", type: "text", label: "Title in Arabic" },
    {
      fieldName: "description",
      type: "textarea",
      label: "Description in English",
    },
    {
      fieldName: "descriptionAr",
      type: "textarea",
      label: "Description in Arabic",
    },
    { fieldName: "timing", type: "text", label: "Timing in English" },
    { fieldName: "timingAr", type: "text", label: "Timing in Arabic" },

    {
      fieldName: "rules",
      type: "chips",
      label: "Rules in English",
      options: [
        // { value: "No Smoking", label: "No Smoking" },
        // { value: "No Loud voice", label: "No Loud voice" },
        // { value: "vanilla", label: "Vanilla" },
      ],
    },
    {
      fieldName: "rulesAr",
      type: "chips",
      label: "Rules in Arabic",

      options: [
        // { value: "No Smoking", label: "No Smoking" },
        // { value: "No Loud voice", label: "No Loud voice" },
        // { value: "vanilla", label: "Vanilla" },
      ],
    },
    {
      fieldName: "closingPeriod.from",
      type: "date",
      label: "Closing Period from",
    },
    {
      fieldName: "closingPeriod.to",
      type: "date",
      label: "Closing Period to",
    },
    {
      fieldName: "typeId",
      type: "select",
      label: "Facility Type",
      loadOptions: async () => {
        const facilityTypes = await getFacilityTypes();
        const mappedFacilityTypes = facilityTypes?.map((el) => ({
          value: el._id,
          label: el.type,
        }));
        return mappedFacilityTypes;
      },
      // value: formik.values.typeId._id,
      // defaultInputValue: formik.values.typeId.type,
      defaultValue: {
        value: formik.values?.typeId?._id,
        label: facilityDetails?.typeId.type,
      },
    },
    {
      fieldName: "venueId",
      type: "select",
      label: "Select Venue",
      loadOptions: async () => {
        const venues = await getUserVenues();
        const mapped = venues?.map((el) => ({
          value: el._id,
          label: el.name,
        }));
        return mapped;
      },
      // value: formik.values.typeId._id,
      // defaultInputValue: formik.values.typeId.type,
      // defaultValue: facilityDetails.venueId?.title,
      defaultValue: {
        value: formik.values?.venueId?._id,
        label: facilityInfoInputFromTable?.venueId?.name,
      },
    },
    {
      fieldName: "maxAllowedSlots",
      type: "number",
      label: "Max Allowed Slots",
      // className:"col-12"
    },
  ];
  const [bookingTime, setBookingTime] = useState({
    value: facilityDetails?.weekCalender.timeForBooking,
    label: t(facilityDetails?.weekCalender.timeForBooking),
  });
  const timeForBookingFields = [
    {
      fieldName: "weekCalender.timeForBooking",
      type: "select",
      label: "Booking Time",
      options: [
        {
          value: "30min",
          label: "30min",
        },
        {
          value: "1hr",
          label: "1hr",
        },
        {
          value: "1.5hr",
          label: "1.5hr",
        },
      ],
      value: bookingTime,
      onChange: (option) => {
        setBookingTime(option);
      },
    },
  ];

  const { updateFacility, getFacilityTypes, facilitiesTypesLoading } =
    useFacilitiesStore((state) => state);
  const { getUserVenues } = useVenuesStore((state) => state);

  const imageUploaderHandler = (img) => {
    if (!img) return;
    formik.setFieldValue("image", img);
  };

  const resetImage = () => {
    formik.setFieldValue("image", null);
  };

  return (
    <Modal
      keyboard={false}
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
      {...restProps}
    >
      <ModalHeader>Edit Facility</ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit} className="row">
          {fieldsToRender?.map((field) => (
            <div
              key={field.fieldName}
              // className={`flex-fill ${field.fullWidth && "w-100"}`}
              className={field.className || "col-6"}
            >
              {RenderFormikInput(formik, field)}
            </div>
          ))}
          {timeForBookingFields?.map((field) => (
            <div className="col-6" key={field.fieldName}>
              {RenderFormikInput(formik, field)}
            </div>
          ))}
          <ImageUploader
            getImagesLinks={imageUploaderHandler}
            cb={resetImage}
            className="mt-3"
          />
          {formik.values.image && (
            <div className="mt-3 position-relative d-flex flex-column justify-content-center align-items-center gap-2 text-center">
              <img
                src={formik.values.image.small}
                alt="uploaded-img"
                height={"50"}
              />
            </div>
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          className="order-1"
          type="submit"
          onClick={() => {
            formik.submitForm();
          }}
          disabled={!formik.dirty || !formik.isValid || facilitiesTypesLoading}
        >
          {facilitiesTypesLoading ? "Loading" : "Save"}
        </Button>
        {!facilitiesTypesLoading && (
          <Button color="danger" onClick={toggle}>
            {t("Cancel")}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
