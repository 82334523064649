import React, { useRef, useState } from "react";
import { Button, Col, Input } from "reactstrap";
import { axiosHelper } from "../../stores/helpers";
import { t } from "i18next";

export default function ImageUploader({ getImagesLinks, cb = () => {} }) {
  let [image, setImage] = useState(undefined);
  let [isLoading, setIsLoading] = useState(undefined);

  const handleImages = (e) => {
    let image = e.target.files[0];
    setImage(image);
    setUploaded(false);
    cb();
  };
  const [inputKey, setInputKey] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const uploadImage = async () => {
    if (!image) return;

    let formData = new FormData();
    formData.append("image", image);
    setIsLoading(true);
    const { result, data } = await axiosHelper.post("/image/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (!result) {
      // console.log(data);
      setIsLoading(false);
    }
    getImagesLinks && getImagesLinks(data);
    setUploaded(true);
    setIsLoading(false);
    resetInput();
  };

  const resetInput = () => {
    imgRef.current.value = null;
    setInputKey(new Date());
  };

  const imgRef = useRef(null);

  return (
    <Col className="d-flex gap-2">
      <Input
        type="file"
        // multiple={multiple}
        onChange={handleImages}
        ref={imgRef}
        key={inputKey}
        // accept="image/*"
        accept="image/png, image/jpg, image/jpeg"
      />
      <Button
        color={uploaded ? "success" : "primary"}
        onClick={uploadImage}
        disabled={!image || isLoading || uploaded}
      >
        {isLoading ? "Loading..." : uploaded ? t("Done") : t("Upload")}
      </Button>
    </Col>
  );
}
